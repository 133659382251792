// ERRORS ACTIONS
export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

//AUTH ACTIONS
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_VENDOR = 'CREATE_VENDOR'
export const OTP_AUTHENTICATE_SUCCESS = 'OTP_AUTHENTICATE_SUCCESS'
export const OTP_AUTHENTICATE_FAIL = 'OTP_AUTHENTICATE_FAIL'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
export const REGISTER_VENDOR_SUCCESS = 'REGISTER_VENDOR_SUCCESS'
export const REGISTER_VENDOR_FAIL = 'REGISTER_VENDOR_FAIL'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_USER_DETAIL = 'SET_USER_DETAIL'
export const LOGIN_USER = 'LOGIN_USER'
export const SHOW_POP_UP = 'SHOW_POP_UP'
export const HIDE_POP_UP = 'HIDE_POP_UP'

//PAGE ACTIONS
export const PAGE_LOADING = 'PAGE_LOADING'
export const PAGE_LOADED = 'PAGE_LOADED'
export const SET_INDEX_DATA = 'SET_INDEX_DATA'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const UPDATE_PROPERTY_STATUS = 'UPDATE_PROPERTY_STATUS'
export const UPDATE_POST_STATUS = 'UPDATE_POST_STATUS'
export const REPORT_OPTION = 'REPORT_OPTION'
export const REPORT_MATERIAL = 'REPORT_MATERIAL'
export const GET_PROFESSIONS = 'GET_PROFESSIONS'
export const ADD_LIKE = 'ADD_LKE'
export const FOLLOW_UNFOLLOW_PROFESSIONAL = 'FOLLOW_UNFOLLOW_PROFESSIONAL'
export const SET_HOME_DATA = 'SET_HOME_DATA'
export const ADD_COMMENTS = 'ADD_COMMENTS'
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const LOAD_ALL_CARDS = 'LOAD_ALL_CARDS'
export const SET_DEFAULT_ALL_CARDS = 'SET_DEFAULT_ALL_CARDS'
export const SET_PACKAGE_DETAILS = 'SET_PACKAGE_DETAILS'
export const SET_CURRENT_USERS_CARD = 'SET_CURRENT_USERS_CARD'
export const SET_APP_FEATURES = 'SET_APP_FEATURES'

//USERS ACTION
export const SET_VENDORS = 'SET_VENDORS'
export const SET_VENDORS_BY_SEARCH_PAGINATION =
  'SET_VENDORS_BY_SEARCH_PAGINATION'
export const SET_SINGLE_VENDOR = 'SET_SINGLE_VENDOR'
export const SET_SINGLE_VENDORS_PROPERTIES = 'SET_SINGLE_VENDORS_PROPERTIES'
export const SET_MEETINGS_LIST = 'SET_MEETINGS_LIST'

//PROPERTY ACTIONS
export const PROPERTY_DROP_DWON = 'PROPERTY_DROP_DWON'
export const GET_SINGLE_PROPERTY = 'GET_SINGLE_PROPERTY'
export const GET_ALL_PROPERTIES = 'GET_ALL_PROPERTIES'
export const ADD_COMMENTS_PROP_USER = 'ADD_COMMENTS_PROP_USER'

// MPAC FLOW ACTIONS
export const PAGE_LOADING_MPAC = 'PAGE_LOADING_MPAC'
export const PAGE_LOADED_MPAC = 'PAGE_LOADED_MPAC'
export const SET_PRODUCT_CATALOGUE_MPAC = 'SET_PRODUCT_CATALOGUE_MPAC'
export const SET_REPORTS_MPAC = 'SET_REPORTS_MPAC'
export const SET_MPAC_PROPERTY_RESULT = 'SET_MPAC_PROPERTY_RESULT'
export const SET_MPAC_PROPERTY_AUTH_TOKEN = 'SET_MPAC_PROPERTY_AUTH_TOKEN'
export const SET_MPAC_RECEIPT_DATA = 'SET_MPAC_RECEIPT_DATA'
export const SET_MPAC_AUTO_FILL_PROPERTY_DATA =
  'SET_MPAC_AUTO_FILL_PROPERTY_DATA'
