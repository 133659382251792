import React, { Component } from 'react'

class privacy extends Component {
  state = {}
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <h2 className="pxp-page-header">
                Terms of Use and Privacy Policy
              </h2>
              <p className="pxp-text-light" style={{ marginTop: '100px' }}>
                Use of this web site (the “Site”) requires that you acknowledge
                and consent to these Terms. Please read these Terms carefully
                before using this Site. 1. Website Terms and Conditions. House
                Up Inc. (“House Up”) maintains this Site and provides the
                materials accessible by the user, which may include text,
                information, communications, images, audio clips, sounds video
                clips, software, links to external web sites and other materials
                including materials provided by third parties (collectively the
                "Content"), for informational purposes only. 2. General
                Disclaimers. House Up intends for the Content on this Site to be
                accurate and reliable, however, the Content is provided to you
                "as is" and "as available". House Up is not responsible toward
                you or any third party for any errors or omissions of any kind
                in the Content, and disclaim all warranties and conditions,
                express or implied, including, without limitation, implied
                warranties and conditions of non infringement, merchantability
                and fitness for a particular purpose. Links available on this
                Site will allow you to link to web sites not maintained or
                controlled by House Up. House Up provides these links for your
                convenience only and is not responsible for and does not warrant
                the (including but not limited to) accuracy, completeness,
                non-infringement, error, omission, interruption, defect,
                merchantability or fitness for a particular purpose of any
                information or content available through these links, and make
                no representation or endorsement whatsoever about any other web
                site which you may access through this Site. Please be aware
                that it is your responsibility to ensure that any web site you
                choose to use is free of potentially destructive items such as
                computer viruses. IN NO EVENT WILL House Up BE LIABLE FOR ANY
                DAMAGES WHATSOEVER, INCLUDING SPECIAL, INDIRECT, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE
                USE OR PERFORMANCE OF THIS SITE OR ANY OTHER SITE YOU MAY ACCESS
                WHILE USING THIS SITE OR YOUR USE OF THE CONTENT, EVEN IF
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 3. Use of this Site.
                You may not use this Site in any way that would interfere with
                its operation. You may not post, upload, publish, transmit or
                distribute information or other material on this Site that: 3.1.
                could constitute or encourage conduct that would amount to a
                criminal offence or violate the rights of any third parties;
                3.2. in any way whatsoever, either explicitly or implicitly,
                would harm the reputation of any person or business by libel or
                slander; 3.3. is protected by copyright, or other intellectual
                property right, or derivative works thereof, without obtaining
                permission of the copyright holder; 3.4. contains any
                intellectual property, including without limitation any
                copyright, trade-mark, or patent, that would infringe on the
                intellectual property rights not owned by you; 3.5. could be
                considered as a use of this Site that is contrary to law or
                electronic etiquette, or which would adversely impact the use of
                this Site or the Internet by other users, including the posting
                or transmitting of information or software containing viruses or
                other disruptive components; or 3.6. contains any distasteful or
                offensive material including, but not limited to, material
                intended for an adult audience.House Up reserves the right to
                remove any information that you post, upload, publish, transmit
                or distribute or otherwise use on this Site at any time, at its
                sole discretion and without prior notice. 4. License. You hereby
                grant House Up a perpetual license in and a perpetual right to
                use any information or other material you post, upload, publish,
                and transmit in connection with your use of this Site. 5.
                Linking. You may create a hyperlink (the “Link”) to this Site
                provided that: 5.1. you do not replicate any of the Content of
                this Site; 5.2. you do not create a frame or any other bordered
                environment around this Site or its Content; 5.3. the Link shall
                not imply any endorsement of any product or services; 5.4. the
                website linking to this Site does not contain any distasteful or
                offensive material; and 5.5. the website linking to this Site
                does not contain any intellectual property, including without
                limitation any copyright, trademark, or patent, that would
                infringe on intellectual property rights not owned by you. House
                Up reserves the right to require you to remove any Link to this
                Site at its sole discretion, at any time and without notice (the
                “Request”). You agree to immediately comply with our Request. 6.
                Web Scraping/Harvesting. The framing, scraping, data-mining,
                extraction or collection of the Content of this site in any form
                and by any means whatsoever is strictly prohibited. Furthermore,
                you may not mirror any material contained on this site on any
                other server without House Up’s express written authorization.
                7. Copyright. All Content published on or otherwise accessible
                through this Site is protected by copyright. The Content, and
                the copyright in the Content, are owned or controlled by House
                Up or House Up’s content suppliers. You may only use or
                reproduce the Content for your own personal and non-commercial
                use provided you do not modify the Content. The Content may not
                be otherwise used, reproduced, broadcast, published or
                retransmitted without the prior written permission of the
                copyright holder. You must abide by all copyright notices,
                information and restrictions contained in any Content on or
                accessed through this Site and maintain such notices in the
                Content. 8. Trade-marks. You may not use any trade-mark
                displayed on the Site without the express written permission of
                House Up or the relevant owner of the trade-mark in question. 9.
                Changes. House Up reserves the right to change or remove any
                Content from this Site, in whole or in part, at its sole
                discretion, at any time, without notice. 10. Privacy,
                Confidential Information and Consent. House Up’s Privacy Policy
                is a binding and integral part of these Terms. In addition, you
                hereby consent: to (a) House Up contacting you by email or
                telephone to discuss the services House Up is then providing to
                you (including the classified listing of your vehicle) and any
                related or other products and/or services offered by House Up;
                (b) to House Up providing your contact information to third
                parties in connection with the provision of products and
                services for which you have subscribed; and (c) where
                applicable, any such third party contacting you in connection
                with the provision of the applicable products and/or services.
                11. Indemnity. You agree to defend, indemnify and hold House Up
                and its affiliates as well as their respective directors,
                officers, trustees and employees harmless from any and all
                liabilities, costs and expenses, including reasonable attorneys'
                fees, related to any violation of these Terms by you or users of
                your account, or in connection with the use of this Site or the
                placement or transmission of any information or other materials
                on this Site by you or users of your account. 12. Territory/No
                Solicitation. This Site was created in Canada. House Up’s
                products and services are offered in our operating territory.
                Some products and services referred to on this Site may not be
                available in all parts of our territory. Consult your local
                House Up business office (all offices of House Up are listed
                www.House Up.ca), for information regarding the products and
                services that may be available to you in your area. No part of
                the Site should be taken to constitute an offer or solicitation
                to buy or sell products or services. Some products or services
                mentioned on the Site may only be available in certain areas or
                jurisdictions. Any products or services mentioned on the Site
                are made available only where they may be lawfully made
                available or offered for sale.13. Miscellaneous. 13.1. The Terms
                of this Site, including all other documents referenced herein,
                constitute the entire agreement between House Up and you with
                respect to your use of this Site. It supersedes and replaces all
                prior or contemporaneous understandings or agreements, written
                or oral, with respect to this Site. 13.2. House Up reserves the
                exclusive right to modify these Terms at any time. Non-material
                changes and clarifications will take effect immediately. Any
                material changes will take effect 30 days after their posting.
                The effective date of the changes will appear at the top of this
                page. Any continued use of this Site will be deemed conclusive
                of your acceptance of the modified Terms. 13.3. House Up’s
                failure to insist upon or enforce strict performance of any
                provision of this Agreement shall not be construed as a waiver
                of any provision or right. If any of these Terms is determined
                to be void, invalid or otherwise unenforceable by a court of
                competent jurisdiction, such determination shall not affect the
                remaining provisions hereof. 13.4. This Agreement shall be
                governed by and construed in accordance with the laws of Québec
                and the federal laws of Canada applicable therein. The parties
                hereby agree and attorn to the exclusive jurisdiction of the
                Courts of Québec. 13.5. The parties have required that these and
                all related documents be drawn up in English. 14. Consent. By
                clicking on the “Next” button below, you acknowledge that you
                have read, understood and agree to be bound by these Terms just
                as if you had signed them. If you do not agree to these Terms
                please exit this Site immediately either by closing the
                appropriate application or using the back function on your b
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default privacy
