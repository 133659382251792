import React from 'react'
import { Link } from 'react-router-dom'

const detailStringHandler = (detailArray) => {
  let detail = ''
  if (detailArray && detailArray.length) {
    detailArray.map((data, idx) => {
      if (idx < 15) {
        detail += data.name + ', '
      }
      return data
    })
  }
  return detail
}

const Reports = ({
  activeTabHandler,
  reports,
  onChangeHandler,
  productCatalogueName,
  mpacReportIds,
  history,
}) => {
  const stepHandler = (type) => {
    if (type === 'back') {
      activeTabHandler(0)
    } else if (type === 'skip') {
      if (productCatalogueName === 'manual') {
        history.push('/add-property')
      } else {
        activeTabHandler(2)
      }
    } else if (type === 'continue') {
      if (productCatalogueName === 'manual' && mpacReportIds.length < 1) {
        history.push('/add-property')
      } else {
        activeTabHandler(2)
      }
    }
  }

  return (
    <div className="wrapper">
      <div className="mpac_listing mpac_wrapper">
        <h2>You also like</h2>
        {reports && reports.length
          ? reports.map((data, idx) => (
              <div className="mpax_list filldata_manually" key={idx}>
                <div className="media">
                  <input
                    type="checkbox"
                    onChange={onChangeHandler}
                    id={data.id}
                    name="reports"
                    value={data.id}
                    className="mr-3"
                  />
                  <div className="media-body">
                    <h3 className="mt-0">
                      {' '}
                      {data.displayName ? data.displayName : ''}{' '}
                      <Link to="#"> {data.price ? '$' + data.price : ''} </Link>
                    </h3>
                    <p>
                      {' '}
                      {detailStringHandler(
                        data.detail && data.detail.length ? data.detail : [],
                      )}{' '}
                    </p>
                    <Link to="#">Learn more..</Link>
                  </div>
                </div>
              </div>
            ))
          : ''}

        <button
          onClick={() => stepHandler('back')}
          className="mpac_listing_anchor_skip"
        >
          Back
        </button>
        <button
          onClick={() => stepHandler('skip')}
          className="mpac_listing_anchor_skip"
        >
          Skip
        </button>
        <button
          onClick={() => stepHandler('continue')}
          className="mpac_listing_anchor"
        >
          Continue
        </button>
      </div>
      <div className="clear" />
    </div>
  )
}

export default Reports
