import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getMPACPaymentReceipt,
  createCreditCardToken,
  markCreditCardDefault,
  mpacPayment,
} from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask'

var jwt = require('jsonwebtoken')

const Checkout = ({
  productCatalogueIds,
  mpacReportIds,
  user,
  autoFillReqPacket,
  productCatalogueName,
  activeTabHandler,
}) => {
  const dispatch = useDispatch()
  const mpac = useSelector((state) => state.mpac)
  const [cardNumber, setCardNumber] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [cvv, setCvv] = useState('')
  const [addCardToggle, setAddCardToggle] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let reportIds = ''
    mpacReportIds.map((data, index) => {
      if (index === mpacReportIds.length - 1) {
        reportIds += data
      } else {
        reportIds += data + ','
      }
      return data
    })
    console.log('checking reportIds: ', reportIds)
    const reqPacket = {
      productCatalogueIds,
      mpacReportIds: reportIds,
      userId: user.userId,
    }
    dispatch(getMPACPaymentReceipt(reqPacket, mpac.authToken))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addCardToggleHandler = () => {
    setAddCardToggle(!addCardToggle)
  }

  const onChange = (cardId) => {
    const data = {
      cardId: cardId,
      userId: user.userId,
    }
    dispatch(markCreditCardDefault(data))
  }

  const addCard = (e) => {
    e.preventDefault()
    const jwtSecretKey = process.env.REACT_APP_JWT_SECRET_KEY
    const now = new Date()
    const expTime = Math.round(now.getTime() / 1000) + 2000
    const cardNum = cardNumber.replace(' ', '')
    var token = jwt.sign(
      {
        cardNumber: cardNum,
        expiryDate: expiryDate,
        cvv: cvv,
        exp: expTime,
      },
      jwtSecretKey,
    )
    const data = {
      channel: 'web',
      token: token,
      userId: user.userId,
    }
    dispatch(createCreditCardToken(data))
  }

  const onCheckoutHandler = () => {
    const reqPacket = {
      userId: user.userId,
      roll:
        mpac &&
        mpac.mpacPropertyResult &&
        mpac.mpacPropertyResult.results &&
        mpac.mpacPropertyResult.results.length &&
        mpac.mpacPropertyResult.results[0].roll
          ? mpac.mpacPropertyResult.results[0].roll
          : '',
      channel: 'web',
      currency: 'cad',
      paymentReciptId:
        mpac && mpac.receiptData && mpac.receiptData.paymentReciptId
          ? mpac.receiptData.paymentReciptId
          : '0',
    }
    dispatch(
      mpacPayment(
        reqPacket,
        mpac.authToken,
        productCatalogueName,
        autoFillReqPacket,
        history,
      ),
    )
  }

  return (
    <div className="wrapper">
      <div className="mpac_wrapper">
        <div className="main">
          {mpac &&
          mpac.receiptData &&
          mpac.receiptData.paymentDetails &&
          mpac.receiptData.paymentDetails.length
            ? mpac.receiptData.paymentDetails.map((data, idx) => (
                <div className="main_inner" key={idx}>
                  <div>{data.name}</div>
                  <div>${data.price}</div>
                </div>
              ))
            : ''}
          <div className="main_inner">
            <div>
              {' '}
              {mpac && mpac.receiptData && mpac.receiptData.taxLabel
                ? mpac.receiptData.taxLabel
                : 'Tax'}{' '}
            </div>
            <div>
              $
              {mpac && mpac.receiptData && mpac.receiptData.tax
                ? mpac.receiptData.tax
                : '0.0'}
            </div>
          </div>
          <div className="main_inner total">
            <div>
              {mpac && mpac.receiptData && mpac.receiptData.totalLabel
                ? mpac.receiptData.totalLabel
                : 'Total'}
            </div>
            <div>
              $
              {mpac && mpac.receiptData && mpac.receiptData.totalPrice
                ? mpac.receiptData.totalPrice
                : '0.0'}
            </div>
          </div>

          <div className="col-md-6">
            {user && user.creditCardList
              ? user.creditCardList.map((data, idx) => (
                  <div
                    className="property-plan-div"
                    key={idx}
                    onClick={() => onChange(data.cardId && data.cardId)}
                  >
                    <div className="card-selection">
                      <div className="dashboard-newsfeed-content">
                        <div>
                          <div className="row">
                            <div className="col-md-3 logo-modal">
                              <img
                                src={
                                  data.brand === 'Visa'
                                    ? require('../../../../assets/images/ic_visa.svg')
                                    : require('../../../../assets/images/ic_visa_master_card.svg')
                                }
                                alt=""
                                style={{
                                  width: '46px',
                                  height: '14px',
                                  marginTop: '20px',
                                }}
                              />
                            </div>
                            <div className="col-md-9 visa-card-selection">
                              <div>
                                <div className="card ">
                                  {' '}
                                  {data.brand === 'Visa'
                                    ? 'VISA'
                                    : 'MASTER'}{' '}
                                  Card
                                </div>
                                <div className="card-description">
                                  {' '}
                                  **** **** **** {data.last4}{' '}
                                </div>
                              </div>
                              {data.isDefault ? (
                                <img
                                  src={require('../../../../assets/images/ic_check_sel.svg')}
                                  alt=""
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ''}
            <div>
              <div>
                <button className="more-options" onClick={addCardToggleHandler}>
                  Add New Card
                </button>
                {addCardToggle ? (
                  <form onSubmit={addCard}>
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ margin: '15px 0px 0px' }}
                      >
                        <InputMask
                          className={'form-control'}
                          mask="9999 9999 9999 9999"
                          placeholder="Card Number"
                          name="cardNumber"
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          required
                        >
                          {(inputProps) => (
                            <input type="text" {...inputProps} />
                          )}
                        </InputMask>
                      </div>
                      <div
                        className="col-md-6"
                        style={{ margin: '15px 0px', paddingRight: '0px' }}
                      >
                        <InputMask
                          className={'form-control'}
                          mask="99/99"
                          name="expiryDate"
                          value={expiryDate}
                          placeholder="Expire Date"
                          onChange={(e) => setExpiryDate(e.target.value)}
                          required
                        >
                          {(inputProps) => (
                            <input type="text" {...inputProps} />
                          )}
                        </InputMask>
                      </div>
                      <div className="col-md-6" style={{ margin: '15px 0px' }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="CVV"
                          name="cvv"
                          value={cvv}
                          minLength="3"
                          maxLength="3"
                          required
                          onChange={(e) => setCvv(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-12"
                        style={{ textAlign: 'center', marginBottom: '10px' }}
                      >
                        <button className="btn btn-primary" onClick={addCard}>
                          Add New Card
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => activeTabHandler(2)}
          className="mpac_listing_anchor_skip"
        >
          Back
        </button>
        <button onClick={onCheckoutHandler} className="mpac_listing_anchor">
          Checkout
        </button>
        <div className="clear" />
      </div>
    </div>
  )
}

export default Checkout
