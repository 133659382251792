import React, { Component } from 'react'
import '../../assets/custom-css/style.css'

import SelectReportsTab1 from './components/mpac/selectReports'
import ReportsTab2 from './components/mpac/reports'
import SelectLocationTab3 from './components/mpac/selectLocation'
import CheckoutTab4 from './components/mpac/checkout'
import Spinner from '../../components/common/Spinner'

import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import { Error } from '../../components/common/toastify'

class Mpac extends Component {
  state = {
    activeTab: 0,
    loading: false,
    errors: {},
    user: {},
    productCatalogue: {},
    reports: [],
    mpacPropertyResult: {},

    autoFillReqPacket: {},

    productCatalogueIds: '',
    productCatalogueName: '',
    mpacReportIds: [],
    streetNumber: '',
    streetName: '',
    postalCode: '',
    address: '',
  }

  static getDerivedStateFromProps(props, state) {
    const { mpac, errors, auth } = props
    let stateChanged = false
    let changedState = {}

    if (
      auth &&
      auth.user &&
      JSON.stringify(state.user) !== JSON.stringify(auth.user)
    ) {
      changedState.user = auth.user
      stateChanged = true
    }

    if (
      mpac &&
      mpac.productCatalogue &&
      JSON.stringify(state.productCatalogue) !==
        JSON.stringify(mpac.productCatalogue)
    ) {
      changedState.productCatalogue = mpac.productCatalogue
      stateChanged = true
    }

    if (
      mpac &&
      mpac.mpacPropertyResult &&
      mpac.mpacPropertyResult.propertiesFound &&
      JSON.stringify(state.mpacPropertyResult) !==
        JSON.stringify(mpac.mpacPropertyResult)
    ) {
      changedState.mpacPropertyResult = mpac.mpacPropertyResult
      stateChanged = true
      if (changedState.mpacPropertyResult.propertiesFound > 0) {
        changedState.activeTab = 3
      } else {
        Error('Please enter valid address!')
      }
    }

    if (
      mpac &&
      mpac.reports &&
      JSON.stringify(state.reports) !== JSON.stringify(mpac.reports)
    ) {
      changedState.reports = mpac.reports
      stateChanged = true
    }

    if (JSON.stringify(state.loading) !== JSON.stringify(mpac.loading)) {
      changedState.loading = mpac.loading
      stateChanged = true
    }
    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors
      stateChanged = true
    }

    if (stateChanged) return changedState
    return null
  }

  componentDidMount() {
    const { onGetProductCatalogue, onGetMPACReport } = this.props
    const { productCatalogue, reports } = this.state

    if (!productCatalogue || !productCatalogue.productCatalogue)
      onGetProductCatalogue()

    if (!reports || !reports.length) onGetMPACReport()
  }

  activeTabHandler = (activeTab) => {
    this.setState({ activeTab })
  }

  selectProductCatalogueIdsHandler = (
    productCatalogueIds,
    productCatalogueName,
  ) => {
    this.setState({ productCatalogueIds, productCatalogueName })
  }

  onChangeHandler = (e) => {
    const { name } = e.target
    if (name === 'reports') {
      const { checked, value } = e.target
      const { mpacReportIds } = this.state
      if (checked) {
        mpacReportIds.push(value)
        this.setState({ mpacReportIds })
      } else {
        const updatedMpacReportIds = mpacReportIds.filter(
          (data) => data !== value,
        )
        this.setState({ mpacReportIds: updatedMpacReportIds })
      }
    } else {
      this.setState({
        [name]: e.target.value,
      })
    }
  }

  autoFillAddressHandler = (streetNumber, streetName, postalCode) => {
    this.setState({ streetNumber, streetName, postalCode })
  }

  handleAddressChange = (address) => {
    this.setState({ address })
  }

  locationSubmitHandler = (e) => {
    e.preventDefault()
    const { address, streetNumber, streetName, postalCode } = this.state
    const { onGetMPACProperty, modelHanlder } = this.props
    const addressArray = address.split(', ')
    const country = addressArray[addressArray.length - 1]
    const province = addressArray[addressArray.length - 2]
    if (country.toLowerCase().indexOf('canada') < 0) {
      modelHanlder('alertPopup', 'We are currently available in cities Canada.')
    } else {
      const reqPacket = {
        streetNumber,
        streetName,
        postalCode,
        province,
        reportType: 'ADDRESS',
        streetType: '',
        streetDirection: '',
        municipality: '',
        unitDesignator: null,
        unitNumber: null,
      }

      const autoFillReqPacket = {
        ...reqPacket,
        reportType: 'RESDETL2',
      }
      this.setState({
        autoFillReqPacket,
      })
      onGetMPACProperty(reqPacket)
    }
  }

  render() {
    const {
      loading,
      activeTab,
      productCatalogue,
      productCatalogueIds,
      productCatalogueName,
      mpacReportIds,
      reports,
      streetName,
      streetNumber,
      postalCode,
      address,
      user,
      autoFillReqPacket,
    } = this.state
    const { history } = this.props
    console.log(`checking this.state: `, this.state)
    let pageContent = ''
    if (loading) pageContent = <Spinner />
    return (
      <div>
        {activeTab === 0 && (
          <SelectReportsTab1
            activeTabHandler={this.activeTabHandler}
            productCatalogueObject={productCatalogue}
            selectProductCatalogueIdsHandler={
              this.selectProductCatalogueIdsHandler
            }
            productCatalogueIds={productCatalogueIds}
          />
        )}
        {activeTab === 1 && (
          <ReportsTab2
            activeTabHandler={this.activeTabHandler}
            reports={reports}
            mpacReportIds={mpacReportIds}
            productCatalogueName={productCatalogueName}
            onChangeHandler={this.onChangeHandler}
            history={history}
          />
        )}
        {activeTab === 2 && (
          <SelectLocationTab3
            activeTabHandler={this.activeTabHandler}
            onChangeHandler={this.onChangeHandler}
            streetNumber={streetNumber}
            streetName={streetName}
            postalCode={postalCode}
            locationSubmitHandler={this.locationSubmitHandler}
            address={address}
            handleAddressChange={this.handleAddressChange}
            autoFillAddressHandler={this.autoFillAddressHandler}
          />
        )}
        {activeTab === 3 && (
          <CheckoutTab4
            activeTabHandler={this.activeTabHandler}
            user={user}
            productCatalogueIds={productCatalogueIds}
            mpacReportIds={mpacReportIds}
            autoFillReqPacket={autoFillReqPacket}
            productCatalogueName={productCatalogueName}
          />
        )}
        {pageContent}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mpac: state.mpac,
    auth: state.auth,
    errors: state.errors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductCatalogue: () => dispatch(actions.getProductCatalogue()),
    onGetMPACReport: () => dispatch(actions.getMPACReport()),
    onGetMPACProperty: (reqPacket) =>
      dispatch(actions.getMPACProperty(reqPacket)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Mpac)
