import React from 'react'
import { Link } from 'react-router-dom'
import { Warn } from '../../../../components/common/toastify'

const detailStringHandler = (detailArray) => {
  let detail = ''
  if (detailArray && detailArray.length) {
    detailArray.map((data, idx) => {
      if (idx < 15) {
        detail += data.name + ', '
      }
      return data
    })
  }
  return detail
}

const SelectReports = ({
  activeTabHandler,
  productCatalogueObject,
  selectProductCatalogueIdsHandler,
  productCatalogueIds,
}) => {
  const manualProductCatalogue =
    productCatalogueObject.manualProductCatalogue &&
    productCatalogueObject.manualProductCatalogue.length
      ? productCatalogueObject.manualProductCatalogue[0]
      : {}
  const productCatalogue =
    productCatalogueObject.productCatalogue &&
    productCatalogueObject.productCatalogue.length
      ? productCatalogueObject.productCatalogue[0]
      : {}

  const continueHandler = (e) => {
    e.preventDefault()
    if (productCatalogueIds && productCatalogueIds !== '') {
      activeTabHandler(1)
    } else {
      Warn('Please select any of the listing!')
    }
  }

  const handleDescriptionString = (text) => {
    return text.split('-')
  }

  return (
    <div className="wrapper">
      <div className="mpac_listing mpac_wrapper">
        <h2>{manualProductCatalogue.name}</h2>
        <div
          className="mpax_list filldata_manually"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            selectProductCatalogueIdsHandler(
              manualProductCatalogue.id ? manualProductCatalogue.id : '',
              'manual',
            )
          }
        >
          <div className="media">
            <span
              style={{ cursor: 'pointer' }}
              className={`mr-3 ${
                manualProductCatalogue.id &&
                manualProductCatalogue.id === productCatalogueIds
                  ? 'mpac-circle-select'
                  : ''
              }`}
            >
              {productCatalogueIds === manualProductCatalogue.id ? (
                <i className="circle-tick-mark far fa-check-circle"></i>
              ) : (
                <i className="far fa-circle" style={{ fontSize: '28px' }}></i>
              )}
            </span>
            <div className="media-body">
              <h3 className="mt-0">
                {manualProductCatalogue.displayName}{' '}
                <Link to="#">
                  {' '}
                  {manualProductCatalogue.price
                    ? manualProductCatalogue.price
                    : ''}
                </Link>
              </h3>
              {manualProductCatalogue.description
                ? handleDescriptionString(
                    manualProductCatalogue.description,
                  ).map((text, index) => (
                    <p>
                      {index !== 0 && '- '}
                      {text}
                    </p>
                  ))
                : ''}
              {/* <Link to="#">Learn more..</Link> */}
            </div>
          </div>
        </div>
        <h4> - OR - </h4>
        <h2>
          {productCatalogue.name}{' '}
          <img
            src={require('../../../../assets/mpac_images/mpac_03.png')}
            alt=""
          />
        </h2>
        <div
          className="mpax_list autofilled_property"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            selectProductCatalogueIdsHandler(
              productCatalogue.id ? productCatalogue.id : '',
              'mpac',
            )
          }
        >
          <div className="media">
            <span
              style={{ cursor: 'pointer' }}
              className={`mr-3 ${
                productCatalogue.id &&
                productCatalogue.id === productCatalogueIds
                  ? 'mpac-circle-select'
                  : ''
              }`}
            >
              {productCatalogueIds === productCatalogue.id ? (
                <i className="circle-tick-mark far fa-check-circle"></i>
              ) : (
                <i className="far fa-circle" style={{ fontSize: '28px' }}></i>
              )}
            </span>
            <div className="media-body">
              <h3 className="mt-0">
                {productCatalogue.displayName}{' '}
                <Link to="#">
                  {' '}
                  {productCatalogue.price
                    ? '$' + productCatalogue.price
                    : ''}{' '}
                </Link>
              </h3>
              <p>
                {' '}
                {productCatalogue.description
                  ? handleDescriptionString(productCatalogue.description).map(
                      (text, index) => (
                        <p>
                          {index !== 0 && '- '}
                          {text}
                        </p>
                      ),
                    )
                  : ''}{' '}
              </p>
              {/* <Link to="#">Learn more..</Link> */}
            </div>
          </div>
        </div>
        <button
          onClick={continueHandler}
          to="#"
          className="mpac_listing_anchor"
        >
          Continue
        </button>
      </div>
      <div className="clear" />
    </div>
  )
}

export default SelectReports
