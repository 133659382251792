import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import * as actionTypes from '../../store/actions/actionTypes'

import { Alert, ModalBody, OverlayTrigger, Popover } from 'react-bootstrap'
import Spinner from '../../components/common/Spinner'

import StoryPreview from '../../components/Popups/others/storyPrevivew'
import Contact from '../../components/Popups/others/contactUsPopup'

import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      reportActiveObject: {},
      errors: {},
      loading: false,
      indexPageData: {},
      user: {},
      contactUsPop: false,
      countryName: '',
      stateName: '',
      cityName: '',
      commentText: '',
      userId: '',
      vendorId: '',
      postId: '',
      storyImageId: '',
      propertyId: '',
      category: '',
      storyToggle: false,
      imageIndex: '',
      activeCommentId: '',
      contactModalState: false,
      shareModalState: false,
      target: null,
      anchorEl: null,
      anchorEl2: null,
      openModal: false,
    }
    this.myRef = React.createRef()
  }

  componentDidMount() {
    const userId =
      this.state.user && this.state.user.userId ? this.state.user.userId : null
    const country = this.props.match.params.country
    const state = this.props.match.params.state
    const city = this.props.match.params.city

    this.setState({
      countryName: country,
      stateName: state,
      cityName: city,
      userId: userId,
    })

    const data = {
      state: state,
      channel: 'web',
      lat: 43.787083,
      lng: -79.497369,
      city: city,
      limit: 10,
      offset: 0,
      loggedInuserId: userId,
      country: country,
    }

    const userData = {
      country: country,
      city: city,
      province: state,
    }
    // if (!this.state.indexPageData.vendorPostPropertiesList) {
    this.props.onGetIndexPageData(data)
    this.props.onGetCountries()
    // }
    setTimeout(() => {
      this.props.onUpdateCurrentLocaiton(userData)
    }, 3000)
  }

  static getDerivedStateFromProps(props, state) {
    const auth = props.auth
    const errors = props.errors
    const page = props.page

    let stateChanged = false
    let changedState = {}

    if (
      page &&
      JSON.stringify(state.indexPageData) !== JSON.stringify(page.indexPageData)
    ) {
      changedState.indexPageData = page.indexPageData
      stateChanged = true
    }

    if (
      page &&
      JSON.stringify(state.reports) !== JSON.stringify(page.reports)
    ) {
      changedState.reports = page.reports
      stateChanged = true
    }

    if (auth && JSON.stringify(state.user) !== JSON.stringify(auth.user)) {
      changedState.user = auth.user
      stateChanged = true
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors
      stateChanged = true
    }

    if (
      page &&
      JSON.stringify(state.loading) !== JSON.stringify(page.loading)
    ) {
      changedState.loading = page.loading
      stateChanged = true
    }

    if (stateChanged) {
      return changedState
    }
    return null
  }

  socialShareHanlder = (platform, id) => {
    if (platform === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${window.location.hostname}/${id}`,
      )
    } else if (platform === 'twitter') {
      window.open(
        `https://twitter.com/intent/tweet?${window.location.hostname}/${id}`,
      )
    } else if (platform === 'linkedin') {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.hostname}/${id}`,
      )
    } else if (platform === 'whatsapp') {
      window.open(
        `https://api.whatsapp.com/send?text=${window.location.hostname}/${id}`,
      )
    } else if (platform === 'instagram') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${window.location.hostname}/${id}`,
      )
    } else if (platform === 'telegram') {
      window.open(
        `https://telegram.me/share/url?url=${window.location.hostname}/${id}`,
      )
    } else if (platform === 'mail') {
      window.open(
        `https://mail.google.com/mail/u/0/?ui=2&view=cm&fs=1&tf=1&su=HouseUp&body=${window.location.hostname}/${id}`,
      )
    }
  }

  addLike = (type, index, like, postId, propertId, vendorId) => {
    const userId =
      this.state.user && this.state.user.userId ? this.state.user.userId : null
    const userName =
      this.state.user && this.state.user.userName
        ? this.state.user.userName
        : null
    let data = {
      vendorId: vendorId,
      postId: postId,
      category: type,
      propertyId: propertId,
      userId: userId,
      action: `${like ? 'Unlike' : 'Like'}`,
    }
    const { user } = this.state
    if (
      user.userStatusDesc === 'Inactive' ||
      user.userStatusDesc === 'Rejected' ||
      user.userStatusDesc === 'In Review'
    ) {
      this.props.modelHanlder(
        'alertPopup',
        `Your Account is been ${
          user.userStatusDesc === 'Inactive'
            ? `${user.userStatusDesc} for 7 days `
            : `${user.userStatusDesc}`
        } due to ${user.rejectionReason}`,
      )
    } else {
      this.props.onLikedPostOrProperty(data, index, userName)
    }
  }

  onChange = (e) => {
    if (e.target.name.indexOf('commentText') >= 0) {
      const activeCommentId = Number(e.target.name.split(',')[1])
      this.setState({
        activeCommentId: activeCommentId,
        commentText: e.target.value,
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  AddComment = (id, typeCategory, index, commentedOnUserId) => {
    const userName =
      this.state.user && this.state.user.userName
        ? this.state.user.userName
        : null
    let {
      userId,
      commentText,
      propertyId,
      postId,
      storyImageId,
      vendorId,
      user,
    } = this.state
    if (typeCategory === 'Post') {
      postId = id
    } else if (typeCategory === 'Property') {
      propertyId = id
    } else if (typeCategory === 'Vendor') {
      vendorId = id
    }

    const data = {
      postId: Number(postId),
      category: typeCategory,
      storyImageId: Number(storyImageId),
      propertyId: Number(propertyId),
      commentedOnUserId: commentedOnUserId,
      commentText: commentText,
      userId: userId,
      vendorId: vendorId,
      phoneNo: user.msisdn,
      channel: 'web',
    }
    const date = moment(Date()).format('YYYY-MM-DD hh:mm:ss')
    const profilePictureUrl =
      user && user.profilePictureUrl ? user.profilePictureUrl : null
    const userFullName = `${user.firstName} ${user.lastName}`
    this.setState({
      commentText: '',
      activeCommentId: '',
    })
    if (
      user.userStatusDesc === 'Inactive' ||
      user.userStatusDesc === 'Rejected' ||
      user.userStatusDesc === 'In Review'
    ) {
      this.props.modelHanlder(
        'alertPopup',
        `Your Account is been ${
          user.userStatusDesc === 'Inactive'
            ? `${user.userStatusDesc} for 7 days `
            : `${user.userStatusDesc}`
        } due to ${user.rejectionReason}`,
      )
    } else {
      this.props.onCommentAdded(
        data,
        index,
        userFullName,
        userName,
        commentedOnUserId,
        profilePictureUrl,
        date,
      )
    }
  }

  followUnfollwProfessionals = (id, index, follow, val) => (e) => {
    e.preventDefault()
    const userId =
      this.state.user && this.state.user.userId ? this.state.user.userId : null
    let data = {
      category: 'Vendor',
      userId: userId,
      action: `${follow ? 'Unfollow' : 'Follow'}`,
      followUnfollowId: `${follow ? '2' : '1'}`,
      vendorId: id,
    }
    const type = val
    const { user } = this.state
    if (
      user.userStatusDesc === 'Inactive' ||
      user.userStatusDesc === 'Rejected' ||
      user.userStatusDesc === 'In Review'
    ) {
      this.props.modelHanlder(
        'alertPopup',
        `Your Account is been ${
          user.userStatusDesc === 'Inactive'
            ? `${user.userStatusDesc} for 7 days `
            : `${user.userStatusDesc}`
        } due to ${user.rejectionReason}`,
      )
    } else {
      this.props.onFollowUnfollowProfessionals(data, index, type)
    }
  }

  limitWordHandler = (str) => {
    const arrayString = str.split(' ')
    let paragraph = ''
    const limit = arrayString.length < 30 ? arrayString.length : 30
    for (let i = 0; i < limit; i++) {
      paragraph += arrayString[i] + ' '
    }
    if (arrayString.length >= 30) {
      paragraph += '...'
    }
    return paragraph
  }

  modelHanlder = (model, id) => {
    if (model === 'contactModalState') {
      this.setState({
        [model]: !this.state[model],
        vendorId: id,
      })
    }
  }

  closeCodelHanlder = (model) => {
    this.setState({
      [model]: false,
    })
  }

  storyHandler = () => {
    this.setState({ storyToggle: !this.state.storyToggle })
  }

  handleDragStart = (e) => {
    e.preventDefault()
  }

  ProfessionalRenderer = (indexData) => {
    let professionalRender = []
    if (indexData && indexData.vendors) {
      indexData.vendors.map((data, index) => {
        if (
          data &&
          (data.userStatusDesc === 'Active' ||
            data.userStatusDesc === 'Approved') &&
          index < 6
        ) {
          professionalRender.push(
            <div key={index} className="suggested-vendors-list">
              <div className="mb-md-3">
                <div className="row">
                  <div className="col-md-2 col-lg-2 col-sm-2">
                    <Link to={`/single-vendor-${data && data.userId}`}>
                      <div className="suggested-vendor-img">
                        <img
                          src={
                            data && data.profilePictureUrl
                              ? data.profilePictureUrl
                              : 'assets/images/dashboard/ic_profile_placeholder.png'
                          }
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-7 col-lg-7 col-sm-7 col-nopadd">
                    <div className="suggested-vendor-name">
                      <p>
                        {data && data.firstName ? data.firstName : ''}{' '}
                        {data && data.lastName ? data.lastName : ''}
                      </p>
                      <span>
                        {data &&
                        data.professionDesc &&
                        data.professionDesc !== 'null'
                          ? data.professionDesc
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-3">
                    <div className="suggested-vendor-follow text-right">
                      {this.state.user.userId === data.userId ? (
                        ''
                      ) : (
                        <Link
                          to="#"
                          onClick={this.followUnfollwProfessionals(
                            data && data.userId && data.userId,
                            index,
                            data && data.isUserFollowedByLoggedInUser,
                            'VendorsRight',
                          )}
                        >
                          {' '}
                          {data && data.isUserFollowedByLoggedInUser === true
                            ? 'Unfollow'
                            : 'Follow'}{' '}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>,
          )
        }
        return data
      })
    }
    return professionalRender
  }

  imageOrVideoRenderer = (url, type) => {
    let renderer = ''
    if (url.indexOf('.mp4') > -1) {
      const videoUrl = url.replace(
        'https://sample-video-app-hu.herokuapp.com',
        'https://houseup-streaming-service.glitch.me',
      )
      renderer = (
        <video
          id="videoPlayer"
          style={{ maxWidth: '100%', width: '100%', height: '320px' }}
          controls
          muted="muted"
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      )
    } else {
      if (type === 'property') {
        renderer = (
          <div
            className="pxp-prop-card-featured"
            style={{
              backgroundImage: `url(${url} )`,
            }}
          >
            <div className="d-table w-100 ">
              <div className="d-table-cell va-bottom featured-height"></div>
            </div>
          </div>
        )
      } else {
        renderer = (
          <div
            className={'dashboard-newsfeed-img'}
            style={{
              backgroundImage: `url(${url})`,
            }}
          ></div>
        )
      }
    }
    return renderer
  }

  handleSuggestedPropertyList = (id) => {
    const page = this.props.page
    let country = ''
    let state = ''
    let city = ''
    page.countries.map((singleCountry) => {
      singleCountry.states.map((singleState) => {
        singleState.cities.map((singleCity) => {
          if (id === singleCity.id) {
            country = singleCountry.name
            state = singleState.name
            city = singleCity.name
          }
        })
      })
    })
    this.props.history.push(`/index-${country}&${state}&${city}`)
  }

  handlePostPropertyDelete = (user, data) => {
    if (data.category === 'Post') {
      const payload = {
        phoneNo: user.msisdn,
        userId: user.userId,
        postId: data.object.postId,
        postStatusId: 8,
        postStatusDes: 'Delete',
      }
      this.props.onUpdatePostStatus(payload)
    } else {
      console.log('user.userId ' + user.userId)
      console.log('data.object.propertId ' + data.object.propertId)
      console.log('user.msisdn ' + user.msisdn)

      const payload = {
        userId: user.userId,
        propertyId: data.object.propertId,
        propertyStatusDesc: 'Deleted',
        phoneNo: user.msisdn,
      }
      this.props.onUpdatePropertyStatus(payload)
    }
  }

  handlePostPropertyHide = (user, data) => {
    if (data.category === 'Post') {
      const payload = {
        phoneNo: user.msisdn,
        userId: user.userId,
        postId: data.object.postId,
        postStatusId: 11,
        postStatusDes: 'Hidden',
      }
      this.props.onUpdatePostStatus(payload)
    } else {
      const payload = {
        userId: user.userId,
        propertyId: data.object.propertId,
        propertyStatusDesc: 'Hidden',
      }
      this.props.onUpdatePropertyStatus(payload)
    }
  }

  handleReportOption = (user) => {
    const payload = {
      userId: user.userId,
      phoneNo: user.msisdn,
    }
    this.props.onReportOption(payload)
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = (e) => {
    this.setState({ anchorEl: null })
  }

  ReportFunction = (data) => (e) => {
    e.preventDefault()
    this.setState({ anchorEl: null, reportActiveObject: data })
    console.log('ReportFunction')
    this.handleReportOption(data.object.user)
    this.onOpenModal()
  }

  onReportsSelectHandler = () => {}

  EditFunction = (e) => {
    this.setState({ anchorEl: null })
    console.log('EditFunction')
  }

  onOpenModal = () =>
    this.setState({
      openModal: true,
    })

  onCloseModal = () =>
    this.setState({
      openModal: false,
    })

  submit = (e, user, data) => {
    e.preventDefault()
    this.setState({ anchorEl: null })

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to delete.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.DeleteFunction(user, data),
        },
        {
          label: 'No',
          onClick: () => alert('Click No'),
        },
      ],
    })
  }

  DeleteFunction = (user, data) => (e) => {
    e.preventDefault()
    console.log('DeleteFunction')
    this.handlePostPropertyDelete(user, data)
  }

  reportHandler = (reason) => {
    const { reportActiveObject, user } = this.state
    const materialOwnerUserId =
      reportActiveObject.category === 'Property'
        ? reportActiveObject.object.propertId
        : reportActiveObject.object.postId
    const reqPacket = {
      category: reportActiveObject.category,
      materialOwnerUserId: `${materialOwnerUserId}`,
      reportedMaterialId: `${reportActiveObject.object.userId}`,
      lang: 'EN',
      phoneNo: `${user.msisdn}`,
      channel: 'web',
      reason,
      reportedByUserId: user.userId,
    }
    const { onReportMaterial } = this.props
    onReportMaterial(reqPacket, this.onCloseModal)
  }
  render() {
    let {
      errors,
      loading,
      indexPageData,
      user,
      cityName,
      countryName,
      stateName,
      commentText,
      storyToggle,
      activeCommentId,
      openModal,
      reports,
    } = this.state
    let pageContent = ''

    const items = []

    if (
      indexPageData &&
      indexPageData.userStories &&
      indexPageData.userStories.length
    ) {
      for (let i = 0; i < indexPageData.userStories.length; i++) {
        let item = (
          <Link to="#" onClick={this.storyHandler}>
            <div style={{ width: '80px' }}>
              <div
                className="pxp-prop-card-dashboard"
                style={{
                  backgroundImage: `url(${
                    indexPageData &&
                    indexPageData.userStories[i] &&
                    indexPageData.userStories[i].stories[0] &&
                    indexPageData.userStories[i].stories[0].storyImages[0] &&
                    indexPageData.userStories[i].stories[0].storyImages[0]
                      .storyImageURL
                  })`,
                }}
              />
              <span className="dashboard-user-name">
                {indexPageData.userStories[i].user.firstName}
              </span>
              <span className="dashboard-user-name withPropertyDealer">
                {indexPageData.userStories[i].user.professionDesc}
              </span>
            </div>
          </Link>
        )
        items.push(item)
      }
    }

    const locationItems = []
    if (
      indexPageData &&
      indexPageData.propertyCounts &&
      indexPageData.propertyCounts.length
    ) {
      for (let i = 0; i < indexPageData.propertyCounts.length; i++) {
        let locationItem = (
          <div className="neighbourhoods_slider">
            <div>
              <div
                className="pxp-prop-card-explore"
                style={{
                  backgroundImage: `url(${
                    indexPageData.propertyCounts[i] &&
                    indexPageData.propertyCounts[i].properties[0] &&
                    indexPageData.propertyCounts[i].properties[0]
                      .imageList[0] &&
                    indexPageData.propertyCounts[i].properties[0].imageList[0]
                      .imageURL
                      ? indexPageData.propertyCounts[i].properties[0]
                          .imageList[0].imageURL
                      : require('../../assets/images/dashboard/ottawa.png')
                  })`,
                }}
              >
                <div className="d-table w-100 ">
                  <div
                    onClick={() => this.props.history.push(`/properties`)}
                    className="d-table-cell va-bottom neighbours-height paddg"
                  >
                    <h2>
                      {indexPageData.propertyCounts[i] &&
                        indexPageData.propertyCounts[i].cityName}
                    </h2>
                    <p>
                      {indexPageData.propertyCounts[i].propertyCount} Properties
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        locationItems.push(locationItem)
      }
    }

    if (loading) {
      pageContent = <Spinner />
    } else {
      pageContent = (
        <React.Fragment>
          {this.state.contactModalState ? (
            <Contact
              show={this.state.contactModalState}
              closeCodelHanlder={this.closeCodelHanlder}
              vendorId={this.state.vendorId}
            />
          ) : (
            ''
          )}

          {!loading && (
            <main>
              <div className="container">
                {errors && errors.message && (
                  <Alert variant="danger">
                    <strong>Error!</strong> {errors.message}
                  </Alert>
                )}
                <div className="row">
                  <div className="col-lg-7 col-md-12">
                    <div className="newsfeed">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="container-fluid pxp-props-carousel-right mt-100 mtpx-100">
                              <div className="pxp-props-carousel-right-container mt-4">
                                <div className="owl-carousel pxp-props-carousel-right-stage-2">
                                  <AliceCarousel
                                    mouseTracking
                                    disableButtonsControls={true}
                                    items={items}
                                    responsive={responsive}
                                  />
                                  {storyToggle ? (
                                    <StoryPreview
                                      show={this.state.storyToggle}
                                      close={this.storyHandler}
                                      storys={
                                        indexPageData &&
                                        indexPageData.userStories
                                      }
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div key={index} className="sort-by">
                          {indexPageData &&
                          indexPageData.vendorPostPropertiesList &&
                          indexPageData.vendorPostPropertiesList.length
                            ? indexPageData.vendorPostPropertiesList.map(
                                (data, index) => (
                                  <React.Fragment key={index}>
                                    {index === 0 && (
                                      <div className="explore-our-neighbours pt-4">
                                        <div className="row">
                                          <div className="col-md-12 col-lg-12 col-sm-12">
                                            <div className="container-fluid pxp-props-carousel-right px-0">
                                              <div className="pxp-props-carousel-right-container">
                                                <h2 className="explore-our-neighbours-heading">
                                                  Explore Our Neighbourhoods
                                                </h2>
                                                <div className="pxp-props-carousel-right-stage-3">
                                                  <div>
                                                    <AliceCarousel
                                                      mouseTracking
                                                      disableButtonsControls={
                                                        true
                                                      }
                                                      items={locationItems}
                                                      responsive={
                                                        locationResponcive
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {(data.category &&
                                      data.category === 'Post' &&
                                      data.object.user &&
                                      data.object.user.userStatusDesc !==
                                        'Suspended' &&
                                      data.object.user.userStatusDesc !==
                                        'Rejected') ||
                                    (data &&
                                      data.category === 'Property' &&
                                      data.object.propertyStatusDesc ===
                                        'Approved' &&
                                      data.object.user &&
                                      data.object.user.userStatusDesc !==
                                        'Suspended' &&
                                      data.object.user.userStatusDesc !==
                                        'Rejected') ? (
                                      <div className="dashboard-newsfeed">
                                        <div className="dashboard-newsfeed-content">
                                          <ul className="news-feed-user-ul">
                                            <li>
                                              <Link
                                                to={`/single-vendor-${
                                                  data &&
                                                  data.object &&
                                                  data.object.user &&
                                                  data.object.user.userId
                                                }`}
                                              >
                                                <span
                                                  className={
                                                    data &&
                                                    data.object &&
                                                    data.object.user &&
                                                    data.object.user
                                                      .userTypeId === 2
                                                      ? 'news-feed-user-img'
                                                      : 'news-feed-user-imgs'
                                                  }
                                                >
                                                  <img
                                                    style={{
                                                      height: '60px',
                                                      borderRadius: `${
                                                        !data.object.user
                                                          .businessName
                                                          ? '50%'
                                                          : '0%'
                                                      }`,
                                                    }}
                                                    src={
                                                      data &&
                                                      data.object &&
                                                      data.object.user &&
                                                      data.object.user
                                                        .profilePictureUrl
                                                        ? data.object.user
                                                            .profilePictureUrl
                                                        : 'assets/images/dashboard/ic_profile_placeholder.png'
                                                    }
                                                    alt=""
                                                  />
                                                </span>
                                              </Link>
                                              <span
                                                style={{
                                                  fontSize: '20px',
                                                  padding: '0px 7px 0px 10px',
                                                }}
                                                className="news-feed-user-name"
                                              >
                                                <Link
                                                  style={{ color: 'black' }}
                                                  to={`/single-vendor-${
                                                    data &&
                                                    data.object &&
                                                    data.object.user &&
                                                    data.object.user.userId
                                                  }`}
                                                >
                                                  <span>
                                                    {data &&
                                                      data.object &&
                                                      data.object.user &&
                                                      data.object.user
                                                        .firstName}{' '}
                                                    {data &&
                                                      data.object &&
                                                      data.object.user &&
                                                      data.object.user
                                                        .lastName}{' '}
                                                  </span>
                                                </Link>
                                                .
                                                {user.userId !==
                                                data.object.user.userId ? (
                                                  <Link
                                                    to=""
                                                    onClick={this.followUnfollwProfessionals(
                                                      data &&
                                                        data.object &&
                                                        data.object.user &&
                                                        data.object.user
                                                          .userId &&
                                                        data.object.user.userId,
                                                      index,
                                                      data.object &&
                                                        data.object.user &&
                                                        data.object.user
                                                          .isUserFollowedByLoggedInUser,
                                                      'PostandProperty',
                                                    )}
                                                  >
                                                    {' '}
                                                    {data.object &&
                                                    data.object.user &&
                                                    data.object.user
                                                      .isUserFollowedByLoggedInUser ===
                                                      true
                                                      ? 'Unfollow'
                                                      : 'Follow'}{' '}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                                <h2
                                                  style={{ fontSize: '20px' }}
                                                >
                                                  {data &&
                                                  data.object &&
                                                  data.object.city
                                                    ? data.object.user.city
                                                    : ' '}{' '}
                                                  .{' '}
                                                  {data &&
                                                    data.object &&
                                                    data.object
                                                      .createDateAndTime}{' '}
                                                </h2>
                                              </span>

                                              <div
                                                className="w3-container three-dots-icon"
                                                style={{
                                                  float: 'right',
                                                  text: 'right',
                                                }}
                                              >
                                                <div
                                                  className="w3-dropdown-hover"
                                                  style={{
                                                    backgroundColor: 'white',
                                                  }}
                                                >
                                                  <i
                                                    className="fas fa-ellipsis-v "
                                                    style={{
                                                      color: 'black',
                                                      backgroundColor: 'white',
                                                    }}
                                                  ></i>
                                                  {/* <button className="w3-button w3-black">Hover Over Me!</button> */}
                                                  <div className="w3-dropdown-content w3-bar-block w3-border">
                                                    {data.object.user.userId ===
                                                    user.userId ? (
                                                      <span>
                                                        <Link
                                                          className="w3-bar-item w3-button"
                                                          onClick={this.DeleteFunction(
                                                            user,
                                                            data,
                                                          )}
                                                        >
                                                          Delete
                                                        </Link>
                                                        <Link
                                                          to={
                                                            data.category ===
                                                            'Post'
                                                              ? '#'
                                                              : `/update-property-${data.object.propertId}`
                                                          }
                                                          className="w3-bar-item w3-button"
                                                        >
                                                          Edit
                                                        </Link>
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <div
                                                          onClick={() =>
                                                            this.handlePostPropertyHide(
                                                              user,
                                                              data,
                                                            )
                                                          }
                                                          className="w3-bar-item w3-button"
                                                        >
                                                          Hide
                                                        </div>
                                                        <Link
                                                          className="w3-bar-item w3-button"
                                                          onClick={this.ReportFunction(
                                                            data,
                                                          )}
                                                        >
                                                          Report
                                                        </Link>
                                                        <Modal
                                                          open={openModal}
                                                          onClose={
                                                            this.onCloseModal
                                                          }
                                                          center
                                                        >
                                                          <ModalBody>
                                                            {reports?.length &&
                                                              reports.map(
                                                                (
                                                                  data,
                                                                  index,
                                                                ) => (
                                                                  <p
                                                                    key={index}
                                                                    className="modal-reports"
                                                                    onClick={() =>
                                                                      this.reportHandler(
                                                                        data.reportOptionTitle,
                                                                      )
                                                                    }
                                                                  >
                                                                    {
                                                                      data.reportOptionTitle
                                                                    }
                                                                  </p>
                                                                ),
                                                              )}
                                                          </ModalBody>
                                                        </Modal>
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                          {data.category &&
                                          data.category === 'Post' ? (
                                            <>
                                              {data.object &&
                                              data.object.postImages[0] &&
                                              data.object.postImages[0].imageURL
                                                ? this.imageOrVideoRenderer(
                                                    data.object &&
                                                      data.object
                                                        .postImages[0] &&
                                                      data.object.postImages[0]
                                                        .imageURL
                                                      ? data.object
                                                          .postImages[0]
                                                          .imageURL
                                                      : '',
                                                  )
                                                : ''}

                                              <div className="dashboard-newsfeed-details">
                                                {data &&
                                                data.category === 'Post' &&
                                                data.object.user
                                                  .userStatusDesc !==
                                                  'Suspended' &&
                                                data.object.user
                                                  .userStatusDesc !== 'Rejected'
                                                  ? data &&
                                                    data.object &&
                                                    this.limitWordHandler(
                                                      data.object.postText,
                                                    )
                                                  : data.object &&
                                                    this.limitWordHandler(
                                                      data.object.description,
                                                    )}
                                              </div>

                                              <div className="row custom-row-styles">
                                                <div className="col-12 post-navbar mt-0">
                                                  <div className="navWrap_rm">
                                                    <span
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        this.addLike(
                                                          data && data.category,
                                                          index,
                                                          data.object &&
                                                            data.object
                                                              .isPostLikedByLoggedInUser,
                                                          data &&
                                                            data.object &&
                                                            data.object
                                                              .postId &&
                                                            data.object.postId,
                                                          0,
                                                          0,
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={
                                                          data.object &&
                                                          data.object
                                                            .isPostLikedByLoggedInUser ===
                                                            true
                                                            ? 'fas fa-heart post-navbar-items'
                                                            : 'far fa-heart post-navbar-items'
                                                        }
                                                      />
                                                    </span>
                                                    <Link
                                                      className="post-navbar-items"
                                                      to={`/comments-${
                                                        data &&
                                                        data.object &&
                                                        data.object.postId &&
                                                        data.object.postId
                                                      }&${
                                                        data && data.category
                                                      }&${index}&${cityName}&${stateName}&${countryName}&${
                                                        data &&
                                                        data.object &&
                                                        data.object.user &&
                                                        data.object.user.userId
                                                      }`}
                                                      style={{
                                                        color: '#706666',
                                                      }}
                                                    >
                                                      <i className="far fa-comment-alt"></i>
                                                    </Link>
                                                    <OverlayTrigger
                                                      trigger="click"
                                                      key={'top'}
                                                      placement={'top'}
                                                      rootClose
                                                      overlay={
                                                        <Popover
                                                          id={`popover-positioned-top`}
                                                        >
                                                          <Popover.Title as="h3">
                                                            Share
                                                          </Popover.Title>
                                                          <Popover.Content
                                                            style={{
                                                              display: 'flex',
                                                              flexWrap: 'wrap',
                                                              gap: '15px',
                                                            }}
                                                          >
                                                            <i
                                                              className="fab fa-facebook-square"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'facebook',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-twitter-square"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'twitter',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-linkedin"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'linkein',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-whatsapp"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'whatsapp',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fa fa-envelope"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'mail',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-telegram"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'telegram',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-instagram"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'instagram',
                                                                  `single-post-${data.object.postId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                          </Popover.Content>
                                                        </Popover>
                                                      }
                                                    >
                                                      <i className="fa fa-share-alt" />
                                                    </OverlayTrigger>

                                                    {/* {data.object.user.userId ===
                                                    user.userId ? (
                                                      ""
                                                    ) : ( */}
                                                    <button
                                                      className="dashboard-newsfeed-contact nodecor"
                                                      data-toggle="modal"
                                                      data-target=""
                                                      onClick={
                                                        user.userStatusDesc ===
                                                          'Inactive' ||
                                                        user.userStatusDesc ===
                                                          'Rejected' ||
                                                        user.userStatusDesc ===
                                                          'In Review'
                                                          ? () =>
                                                              this.props.modelHanlder(
                                                                'alertPopup',
                                                                `Your Account is been ${
                                                                  user.userStatusDesc ===
                                                                  'Inactive'
                                                                    ? `${user.userStatusDesc} for 7 days `
                                                                    : `${user.userStatusDesc}`
                                                                } due to ${
                                                                  user.rejectionReason
                                                                }`,
                                                              )
                                                          : () =>
                                                              this.modelHanlder(
                                                                'contactModalState',
                                                                data &&
                                                                  data.object &&
                                                                  data.object
                                                                    .user &&
                                                                  data.object
                                                                    .user
                                                                    .userId,
                                                              )
                                                      }
                                                    >
                                                      Contact us
                                                    </button>
                                                    {/* )} */}
                                                  </div>
                                                  {data &&
                                                  data.object &&
                                                  data.object.postLikes &&
                                                  data.object.postLikes
                                                    .length &&
                                                  data.object.postLikes
                                                    .length >= 1 ? (
                                                    <div className="likedByText">
                                                      {' '}
                                                      Liked by{' '}
                                                      {data.object.postLikes[
                                                        data.object.postLikes
                                                          .length - 1
                                                      ].userName +
                                                        (data.object.postLikes
                                                          .length <= 1
                                                          ? ''
                                                          : ` and ${
                                                              data.object
                                                                .postLikes
                                                                .length - 1
                                                            } others `)}{' '}
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {data &&
                                                  data.object &&
                                                  data.object.postComments &&
                                                  data.object.postComments
                                                    .length &&
                                                  data.object.postComments
                                                    .length >= 1 ? (
                                                    <div className="personintroinfo">
                                                      <span className="personSingleName">
                                                        {' '}
                                                        {
                                                          data.object
                                                            .postComments[
                                                            data.object
                                                              .postComments
                                                              .length - 1
                                                          ].userName
                                                        }{' '}
                                                        &nbsp;
                                                      </span>
                                                      {
                                                        data.object
                                                          .postComments[
                                                          data.object
                                                            .postComments
                                                            .length - 1
                                                        ].commentText
                                                      }{' '}
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {data &&
                                                  data.object &&
                                                  data.object.postComments &&
                                                  data.object.postComments
                                                    .length >= 1 ? (
                                                    <Link
                                                      className="viewCommentLight"
                                                      to={`/comments-${
                                                        data &&
                                                        data.object &&
                                                        data.object.postId &&
                                                        data.object.postId
                                                      }&${
                                                        data && data.category
                                                      }&${index}&${cityName}&${stateName}&${countryName}&${
                                                        data &&
                                                        data.object &&
                                                        data.object.user &&
                                                        data.object.user.userId
                                                      }`}
                                                    >
                                                      View all{' '}
                                                      {
                                                        data.object.postComments
                                                          .length
                                                      }{' '}
                                                      comments
                                                    </Link>
                                                  ) : (
                                                    ''
                                                  )}

                                                  <div className="comment-send-btn">
                                                    <Link
                                                      style={{ width: '36px' }}
                                                      to={`/single-vendor-${
                                                        user && user.userId
                                                      }`}
                                                    >
                                                      <span
                                                        className={
                                                          user &&
                                                          user.userTypeId === 2
                                                            ? 'news-feed-user-img'
                                                            : 'news-feed-user-imgs'
                                                        }
                                                      >
                                                        <img
                                                          style={{
                                                            width: '100%',
                                                          }}
                                                          alt=""
                                                          src={
                                                            user &&
                                                            user.profilePictureUrl
                                                              ? user.profilePictureUrl
                                                              : 'assets/images/dashboard/ic_profile_placeholder.png'
                                                          }
                                                        />
                                                      </span>
                                                    </Link>
                                                    <div className="comment-input-pointer">
                                                      <input
                                                        className="form-control"
                                                        placeholder="Write your review here..."
                                                        style={{
                                                          height: '35px',
                                                        }}
                                                        name={`commentText,${index}`}
                                                        value={
                                                          activeCommentId ===
                                                          index
                                                            ? commentText
                                                            : ''
                                                        }
                                                        onChange={this.onChange}
                                                      />
                                                      <button
                                                        className=""
                                                        onClick={() =>
                                                          this.AddComment(
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .postId,
                                                            data &&
                                                              data.category,
                                                            index,
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .user &&
                                                              data.object.user
                                                                .userId,
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require('../../assets/images/ic_sent.svg')}
                                                          alt=""
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : data.category &&
                                            data.category === 'Property' &&
                                            data.object.propertyStatusDesc ===
                                              'Approved' &&
                                            data.object.user.userStatusDesc !==
                                              'Suspended' &&
                                            data.object.user.userStatusDesc !==
                                              'Rejected' ? (
                                            <>
                                              <Link
                                                to={`/single-prop-${
                                                  data &&
                                                  data.object &&
                                                  data.object.propertId
                                                }`}
                                              >
                                                {this.imageOrVideoRenderer(
                                                  data &&
                                                    data.object &&
                                                    data.object.imageList[0] &&
                                                    data.object.imageList[0]
                                                      .imageURL
                                                    ? data.object.imageList[0]
                                                        .imageURL
                                                    : '',
                                                  'property',
                                                )}
                                              </Link>
                                              <div className="for-rent">
                                                {data.object &&
                                                data.object.adTitle
                                                  ? data.object.adTitle
                                                  : ''}
                                              </div>
                                              <div className="row">
                                                <div className="col-md-6 col-sm-6 col-6">
                                                  <div className="dashboard-newsfeed-details">
                                                    {' '}
                                                    Property Type:{' '}
                                                    {data.object &&
                                                      data.object.propertyType}
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                  <div
                                                    className="feature-head"
                                                    style={{
                                                      textAlign: 'right',
                                                      paddingRight: '20px',
                                                      fontSize: '20px',
                                                      fontFamily: 'Condensed',
                                                    }}
                                                  >
                                                    <span>
                                                      <b>
                                                        {' '}
                                                        {data.object &&
                                                        data.object.price
                                                          ? `${
                                                              data.object
                                                                .currency &&
                                                              data.object
                                                                .currency.symbol
                                                                ? data.object
                                                                    .currency
                                                                    .symbol
                                                                : '$'
                                                            }${data.object.price.toLocaleString()}`
                                                          : '0'}
                                                      </b>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="dashboard-newsfeed-details">
                                                {data &&
                                                data.category === 'Post'
                                                  ? data &&
                                                    data.object &&
                                                    this.limitWordHandler(
                                                      data.object.postText,
                                                    )
                                                  : data.object &&
                                                    this.limitWordHandler(
                                                      data.object.description,
                                                    )}
                                              </div>

                                              <div
                                                className="row custom-row-styles"
                                                style={{ bottom: '0px' }}
                                              >
                                                <div
                                                  className="col-12 mt-0 post-navbar"
                                                  style={{ marginTop: '0px' }}
                                                >
                                                  <div className="navWrap">
                                                    <span
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        this.addLike(
                                                          data && data.category,
                                                          index,
                                                          data.object &&
                                                            data.object
                                                              .isPropertyLikedByLoggedInUser,
                                                          0,
                                                          data &&
                                                            data.object &&
                                                            data.object
                                                              .propertId &&
                                                            data.object
                                                              .propertId,
                                                          0,
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={
                                                          data.object &&
                                                          data.object
                                                            .isPropertyLikedByLoggedInUser ===
                                                            true
                                                            ? 'fas fa-heart post-navbar-items'
                                                            : 'far fa-heart post-navbar-items'
                                                        }
                                                      />
                                                    </span>
                                                    <Link
                                                      className="post-navbar-items"
                                                      to={`/comments-${
                                                        data &&
                                                        data.object &&
                                                        data.object.propertId &&
                                                        data.object.propertId
                                                      }&${
                                                        data && data.category
                                                      }&${index}&${cityName}&${stateName}&${countryName}&${
                                                        data &&
                                                        data.object &&
                                                        data.object.user &&
                                                        data.object.user.userId
                                                      }`}
                                                      style={{
                                                        color: '#706666',
                                                      }}
                                                    >
                                                      <i className="far fa-comment-alt" />
                                                    </Link>
                                                    <OverlayTrigger
                                                      trigger="click"
                                                      key={'top'}
                                                      rootClose
                                                      placement={'top'}
                                                      overlay={
                                                        <Popover
                                                          id={`popover-positioned-top`}
                                                        >
                                                          <Popover.Title as="h3">
                                                            Share
                                                          </Popover.Title>
                                                          <Popover.Content
                                                            style={{
                                                              display: 'flex',
                                                              flexWrap: 'wrap',
                                                              gap: '15px',
                                                            }}
                                                          >
                                                            <i
                                                              className="fab fa-facebook-square"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'facebook',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-twitter-square"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'twitter',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-linkedin"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'linkedin',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-whatsapp"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'whatsapp',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fa fa-envelope"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'mail',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-telegram"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'telegram',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                            <i
                                                              className="fab fa-instagram"
                                                              onClick={() =>
                                                                this.socialShareHanlder(
                                                                  'instagram',
                                                                  `single-prop-${data.object.propertId}`,
                                                                )
                                                              }
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '40px',
                                                                marginRight:
                                                                  '10px',
                                                                color:
                                                                  '#05B1EA',
                                                              }}
                                                            ></i>
                                                          </Popover.Content>
                                                        </Popover>
                                                      }
                                                    >
                                                      <i className="fa fa-share-alt" />
                                                    </OverlayTrigger>{' '}
                                                    {/* {data.object.user.userId ===
                                                    user.userId ? (
                                                      ""
                                                    ) : ( */}
                                                    <button
                                                      className="dashboard-newsfeed-contact nodecor"
                                                      data-toggle="modal"
                                                      data-target=""
                                                      onClick={
                                                        user.userStatusDesc ===
                                                          'Inactive' ||
                                                        user.userStatusDesc ===
                                                          'Rejected' ||
                                                        user.userStatusDesc ===
                                                          'In Review'
                                                          ? () =>
                                                              this.props.modelHanlder(
                                                                'alertPopup',
                                                                `Your Account is ${
                                                                  user.userStatusDesc ===
                                                                  'Inactive'
                                                                    ? `${user.userStatusDesc} for 7 days `
                                                                    : `${user.userStatusDesc}`
                                                                }`,
                                                              )
                                                          : () =>
                                                              this.modelHanlder(
                                                                'contactModalState',
                                                                data &&
                                                                  data.object &&
                                                                  data.object
                                                                    .user &&
                                                                  data.object
                                                                    .user
                                                                    .userId,
                                                              )
                                                      }
                                                    >
                                                      Contact us
                                                    </button>
                                                    {/* )} */}
                                                  </div>
                                                  {data &&
                                                  data.object &&
                                                  data.object.propertyLikes &&
                                                  data.object.propertyLikes
                                                    .length &&
                                                  data.object.propertyLikes
                                                    .length >= 1 ? (
                                                    <div className="likedByText">
                                                      {' '}
                                                      Liked by{' '}
                                                      {data.object
                                                        .propertyLikes[
                                                        data.object
                                                          .propertyLikes
                                                          .length - 1
                                                      ].userName +
                                                        (data.object
                                                          .propertyLikes
                                                          .length <= 1
                                                          ? ''
                                                          : ` and ${
                                                              data.object
                                                                .propertyLikes
                                                                .length - 1
                                                            } others`)}{' '}
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {data &&
                                                  data.object &&
                                                  data.object
                                                    .propertyComments &&
                                                  data.object.propertyComments
                                                    .length &&
                                                  data.object.propertyComments
                                                    .length >= 1 ? (
                                                    <div className="personintroinfo">
                                                      <span className="personSingleName">
                                                        {' '}
                                                        {
                                                          data.object
                                                            .propertyComments[
                                                            data.object
                                                              .propertyComments
                                                              .length - 1
                                                          ].userName
                                                        }{' '}
                                                        &nbsp;
                                                      </span>
                                                      {
                                                        data.object
                                                          .propertyComments[
                                                          data.object
                                                            .propertyComments
                                                            .length - 1
                                                        ].commentText
                                                      }{' '}
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {data &&
                                                  data.object &&
                                                  data.object
                                                    .propertyComments &&
                                                  data.object.propertyComments
                                                    .length >= 1 ? (
                                                    <Link
                                                      className="viewCommentLight"
                                                      to={`/comments-${
                                                        data &&
                                                        data.object &&
                                                        data.object.propertId &&
                                                        data.object.propertId
                                                      }&${
                                                        data && data.category
                                                      }&${index}&${cityName}&${stateName}&${countryName}`}
                                                    >
                                                      View all{' '}
                                                      {
                                                        data.object
                                                          .propertyComments
                                                          .length
                                                      }{' '}
                                                      comments
                                                    </Link>
                                                  ) : (
                                                    ''
                                                  )}

                                                  <div className="comment-send-btn">
                                                    <Link
                                                      style={{ width: '36px' }}
                                                      to={`/single-vendor-${
                                                        user && user.userId
                                                      }`}
                                                    >
                                                      <span
                                                        className={
                                                          user &&
                                                          user.userTypeId === 2
                                                            ? 'news-feed-user-img'
                                                            : 'news-feed-user-imgs'
                                                        }
                                                      >
                                                        <img
                                                          style={{
                                                            width: '100%',
                                                          }}
                                                          alt=""
                                                          src={
                                                            user &&
                                                            user.profilePictureUrl
                                                              ? user.profilePictureUrl
                                                              : 'assets/images/dashboard/ic_profile_placeholder.png'
                                                          }
                                                        />
                                                      </span>
                                                    </Link>
                                                    <div className="comment-input-pointer">
                                                      <input
                                                        className="form-control"
                                                        placeholder="Write your review here..."
                                                        style={{
                                                          height: '35px',
                                                        }}
                                                        name={`commentText,${index}`}
                                                        value={
                                                          activeCommentId ===
                                                          index
                                                            ? commentText
                                                            : ''
                                                        }
                                                        onChange={this.onChange}
                                                      />
                                                      <button
                                                        className=""
                                                        onClick={() =>
                                                          this.AddComment(
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .propertId,
                                                            data &&
                                                              data.category,
                                                            index,
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .user &&
                                                              data.object.user
                                                                .userId,
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require('../../assets/images/ic_sent.svg')}
                                                          alt=""
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    ) : data.category &&
                                      data.category === 'Vendor' &&
                                      data.object.userStatusDesc !==
                                        'Suspended' &&
                                      data.object.userStatusDesc !==
                                        'Rejected' ? (
                                      <>
                                        <div className="vendor-box">
                                          <Link
                                            key={index}
                                            to={`/single-vendor-${
                                              data &&
                                              data.object.userId &&
                                              data.object.userId
                                            }`}
                                          >
                                            <div className="row">
                                              <div className="col-md-9 col-sm-9 col-8">
                                                <div className="vendor-detail">
                                                  <span
                                                    className="news-feed-user-name"
                                                    style={{
                                                      fontSize: '20px',
                                                      padding:
                                                        '0px 7px 0px 0px',
                                                      display: 'block',
                                                      marginBottom: '5px',
                                                    }}
                                                  >
                                                    {data &&
                                                    data.object.firstName
                                                      ? data.object.firstName
                                                      : ''}{' '}
                                                    {data &&
                                                    data.object.lastName
                                                      ? data.object.lastName
                                                      : ''}
                                                    {data &&
                                                    data.object.userId !==
                                                      user.userId ? (
                                                      <>
                                                        .
                                                        <div
                                                          to=""
                                                          onClick={this.followUnfollwProfessionals(
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .userId,
                                                            index,
                                                            data.object &&
                                                              data.object &&
                                                              data.object
                                                                .isUserFollowedByLoggedInUser,
                                                            'Vendors',
                                                          )}
                                                        >
                                                          {' '}
                                                          {data.object &&
                                                          data.object
                                                            .isUserFollowedByLoggedInUser ===
                                                            true
                                                            ? 'Unfollow'
                                                            : 'Follow'}{' '}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </span>

                                                  <p>
                                                    <span>
                                                      {data &&
                                                      data.object &&
                                                      data.object
                                                        .professionDesc &&
                                                      data.object
                                                        .professionDesc !==
                                                        'null'
                                                        ? data.object
                                                            .professionDesc
                                                        : ' '}{' '}
                                                      .
                                                      {data &&
                                                      data.object &&
                                                      data.object
                                                        .createDateAndTime
                                                        ? data.object
                                                            .createDateAndTime
                                                        : ''}
                                                    </span>
                                                  </p>
                                                  <span className="address-span">
                                                    {data && data.object.address
                                                      ? data.object.address
                                                      : ''}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-md-3 col-sm-3 col-4">
                                                <div
                                                  className="vendor-img"
                                                  style={{
                                                    backgroundImage: `url(${
                                                      data &&
                                                      data.object
                                                        .profilePictureUrl
                                                        ? data.object
                                                            .profilePictureUrl
                                                        : 'assets/images/ic_profile_placeholder.png'
                                                    })`,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Link>

                                          <div
                                            className="custom-row-styles"
                                            style={{
                                              bottom: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <div className="col-12 vendor-navbar">
                                              <div className="navWrap">
                                                <span
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    this.addLike(
                                                      data && data.category,
                                                      index,
                                                      data.object &&
                                                        data.object
                                                          .isUserLikedByLoggedInUser,
                                                      0,
                                                      0,
                                                      data &&
                                                        data.object &&
                                                        data.object.userId &&
                                                        data.object.userId,
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className={
                                                      data.object &&
                                                      data.object
                                                        .isUserLikedByLoggedInUser ===
                                                        true
                                                        ? 'fas fa-heart post-navbar-items'
                                                        : 'far fa-heart post-navbar-items'
                                                    }
                                                  />
                                                </span>
                                                <Link
                                                  className="post-navbar-items"
                                                  to={`/comments-${
                                                    data &&
                                                    data.object &&
                                                    data.object.userId &&
                                                    data.object.userId
                                                  }&${
                                                    data && data.category
                                                  }&${index}&${cityName}&${stateName}&${countryName}&${
                                                    data &&
                                                    data.object &&
                                                    data.object.userId
                                                  }`}
                                                  style={{ color: '#706666' }}
                                                >
                                                  <i className="far fa-comment-alt" />
                                                </Link>
                                                <OverlayTrigger
                                                  trigger="click"
                                                  key={'top'}
                                                  rootClose
                                                  placement={'top'}
                                                  overlay={
                                                    <Popover
                                                      id={`popover-positioned-top`}
                                                    >
                                                      <Popover.Title as="h3">
                                                        Share
                                                      </Popover.Title>
                                                      <Popover.Content
                                                        style={{
                                                          display: 'flex',
                                                          flexWrap: 'wrap',
                                                          gap: '15px',
                                                        }}
                                                      >
                                                        <i
                                                          className="fab fa-facebook-square"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'facebook',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fab fa-twitter-square"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'twitter',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fab fa-linkedin"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'linkedin',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fab fa-whatsapp"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'whatsapp',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fa fa-envelope"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'mail',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fab fa-telegram"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'telegram',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="fab fa-instagram"
                                                          onClick={() =>
                                                            this.socialShareHanlder(
                                                              'instagram',
                                                              `single-vendor-${data.object.userId}`,
                                                            )
                                                          }
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: '40px',
                                                            marginRight: '10px',
                                                            color: '#05B1EA',
                                                          }}
                                                        ></i>
                                                      </Popover.Content>
                                                    </Popover>
                                                  }
                                                >
                                                  <i className="fa fa-share-alt" />
                                                </OverlayTrigger>
                                                {/* {user.userId ===
                                                data.object.userId ? (
                                                  ""
                                                ) : ( */}
                                                <button
                                                  className="dashboard-newsfeed-contact nodecor"
                                                  data-toggle="modal"
                                                  data-target=""
                                                  onClick={
                                                    user.userStatusDesc ===
                                                      'Inactive' ||
                                                    user.userStatusDesc ===
                                                      'Rejected' ||
                                                    user.userStatusDesc ===
                                                      'In Review'
                                                      ? () =>
                                                          this.props.modelHanlder(
                                                            'alertPopup',
                                                            `Your Account is been ${
                                                              user.userStatusDesc ===
                                                              'Inactive'
                                                                ? `${user.userStatusDesc} for 7 days `
                                                                : `${user.userStatusDesc}`
                                                            } due to ${
                                                              user.rejectionReason
                                                            }`,
                                                          )
                                                      : () =>
                                                          this.modelHanlder(
                                                            'contactModalState',
                                                            data &&
                                                              data.object &&
                                                              data.object
                                                                .userId,
                                                          )
                                                  }
                                                >
                                                  Contact us
                                                </button>
                                                {/* )} */}
                                              </div>

                                              {data &&
                                              data.object &&
                                              data.object.vendorLikes &&
                                              data.object.vendorLikes.length &&
                                              data.object.vendorLikes.length >=
                                                1 ? (
                                                <div className="likedByText">
                                                  {' '}
                                                  Liked by{' '}
                                                  {data.object.vendorLikes[
                                                    data.object.vendorLikes
                                                      .length - 1
                                                  ].userName +
                                                    (data.object.vendorLikes
                                                      .length <= 1
                                                      ? ''
                                                      : ` and ${
                                                          data.object
                                                            .vendorLikes
                                                            .length - 1
                                                        } others `)}{' '}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                              {data &&
                                              data.object &&
                                              data.object.vendorComments &&
                                              data.object.vendorComments
                                                .length &&
                                              data.object.vendorComments
                                                .length >= 1 ? (
                                                <div className="personintroinfo">
                                                  <span className="personSingleName">
                                                    {' '}
                                                    {
                                                      data.object
                                                        .vendorComments[
                                                        data.object
                                                          .vendorComments
                                                          .length - 1
                                                      ].userName
                                                    }{' '}
                                                    &nbsp;
                                                  </span>
                                                  {
                                                    data.object.vendorComments[
                                                      data.object.vendorComments
                                                        .length - 1
                                                    ].commentText
                                                  }{' '}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                              {data &&
                                              data.object &&
                                              data.object.vendorComments &&
                                              data.object.vendorComments
                                                .length >= 1 ? (
                                                <Link
                                                  className="viewCommentLight"
                                                  to={`/comments-${
                                                    data &&
                                                    data.object &&
                                                    data.object.userId &&
                                                    data.object.userId
                                                  }&${
                                                    data && data.category
                                                  }&${index}&${cityName}&${stateName}&${countryName}&${
                                                    data &&
                                                    data.object &&
                                                    data.object.userId
                                                  }`}
                                                >
                                                  View all{' '}
                                                  {
                                                    data.object.vendorComments
                                                      .length
                                                  }{' '}
                                                  comments
                                                </Link>
                                              ) : (
                                                ''
                                              )}
                                              <div className="comment-send-btn">
                                                <Link
                                                  style={{ width: '36px' }}
                                                  to={`/single-vendor-${
                                                    user && user.userId
                                                  }`}
                                                >
                                                  <span
                                                    className={
                                                      user &&
                                                      user.userTypeId === 2
                                                        ? 'news-feed-user-img'
                                                        : 'news-feed-user-imgs'
                                                    }
                                                  >
                                                    <img
                                                      style={{ width: '100%' }}
                                                      alt=""
                                                      src={
                                                        user &&
                                                        user.profilePictureUrl
                                                          ? user.profilePictureUrl
                                                          : 'assets/images/dashboard/ic_profile_placeholder.png'
                                                      }
                                                    />
                                                  </span>
                                                </Link>
                                                <div className="comment-input-pointer">
                                                  <input
                                                    className="form-control"
                                                    placeholder="Write your review here..."
                                                    style={{ height: '35px' }}
                                                    name={`commentText,${index}`}
                                                    value={
                                                      activeCommentId === index
                                                        ? commentText
                                                        : ''
                                                    }
                                                    onChange={this.onChange}
                                                  />

                                                  <button
                                                    className=""
                                                    onClick={() =>
                                                      this.AddComment(
                                                        data &&
                                                          data.object &&
                                                          data.object.userId,
                                                        data && data.category,
                                                        index,
                                                        data &&
                                                          data.object &&
                                                          data.object.userId,
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require('../../assets/images/ic_sent.svg')}
                                                      alt=""
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </React.Fragment>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                    {indexPageData &&
                    indexPageData?.properties?.length === 0 ? (
                      <div style={{ padding: '25px' }}>
                        <p
                          style={{
                            textAlign: 'center',
                            maxWidth: '252px',
                            margin: 'auto',
                          }}
                        >
                          We are not currently in this area. Please choose
                          another city. We will be coming soon to your area!
                        </p>
                        <p>WE SUGGEST YOU</p>
                        {indexPageData?.propertyCounts?.map((item) => (
                          <div
                            style={{
                              display: 'flex',
                              backgroundColor: '#bfbfbf66',
                              padding: '10px',
                              justifyContent: 'space-between',
                              width: '50%',
                              marginTop: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              this.handleSuggestedPropertyList(item.cityId)
                            }
                          >
                            <p>{item.cityName}</p>

                            <p>{item.propertyCount}</p>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-1" />
                  <div className="col-lg-4 sidebar-fixed-index-property">
                    <div className="side-bar-user mt-100 pt-5">
                      <div className="main-user">
                        <div className="row">
                          {indexPageData && indexPageData.vendors ? (
                            <div className="col-md-3 col-lg-3 col-sm-3">
                              <div
                                className="min-user-img"
                                style={{
                                  backgroundImage: `url(${
                                    user && user.profilePictureUrl
                                      ? user.profilePictureUrl
                                      : 'assets/images/ic_profile_placeholder.png'
                                  })`,
                                  borderRadius: '50%',
                                }}
                              />
                            </div>
                          ) : null}
                          <div className="col-md-9 col-lg-9 col-sm-9  col-nopadd">
                            <div className="main-user-content">
                              <p>
                                {user && user.firstName ? user.firstName : ''}{' '}
                                {user && user.lastName ? user.lastName : ''}
                              </p>
                              <span>
                                {user && user.userName
                                  ? `@${user.userName}`
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="suggested-vendors mt-4 mt-md-4 mb-md-3">
                        {indexPageData && indexPageData.vendors ? (
                          <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-8">
                              <div className="suggested-p">
                                <p>Suggested Professionals For You</p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-4">
                              <div className="suggested-span text-right">
                                <Link to="/professionals">See ALL</Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {this.ProfessionalRenderer(indexPageData).map(
                        (data) => data,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          )}
        </React.Fragment>
      )
    }

    return pageContent
  }
}
const responsive = {
  0: { items: 2 },
  568: { items: 4 },
  1024: { items: 6.5 },
}

const locationResponcive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1.3 },
}
const mapStateToProps = (state) => {
  return {
    page: state.page,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCommentAdded: (
      data,
      index,
      userFullName,
      userName,
      profilePictureUrl,
      date,
    ) =>
      dispatch(
        actions.AddComments(
          data,
          index,
          userFullName,
          userName,
          profilePictureUrl,
          date,
        ),
      ),
    onGetIndexPageData: (userId) => dispatch(actions.getIndexPageData(userId)),
    onFollowUnfollowProfessionals: (data, index, type) =>
      dispatch(actions.followProfessionals(data, index, type)),
    onLikedPostOrProperty: (data, index, userName) =>
      dispatch(actions.AddLike(data, index, userName)),
    onUpdateCurrentLocaiton: (data) =>
      dispatch({ type: actionTypes.SET_CURRENT_LOCATION, payload: data }),
    onGetCountries: () => dispatch(actions.GetCountries()),
    onUpdatePropertyStatus: (data) =>
      dispatch(actions.UpdatePropertyStatus(data)),
    onUpdatePostStatus: (data) => dispatch(actions.UpdatePostStatus(data)),
    onReportOption: (data) => dispatch(actions.ReportOption(data)),
    onReportMaterial: (data, closeModal) =>
      dispatch(actions.ReportMaterial(data, closeModal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
