import React from 'react'

// Marker component
export const Marker = () => {
  const markerStyle = {}

  return (
    <>
      <div style={markerStyle}>
        <img
          src="http://maps.google.com/mapfiles/ms/icons/blue.png"
          alt="marker"
        />
      </div>
    </>
  )
}
