import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Nav } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import cloneDeep from 'lodash/cloneDeep'
import _ from 'lodash'
import * as actions from '../../../store/actions/index'
import { useDispatch, useSelector } from 'react-redux'
import { Marker } from '../components/googleMap/singleMarker'

export default ({
  modelHanlder,
  form1Data,
  singlePropertyData,
  form1DataHandler,
  formShowHandler,
}) => {
  const dispatch = useDispatch()
  const { countries } = useSelector((state) => state.page)

  const [address, setAddress] = useState('')
  const [previewImages, setPreviewImages] = useState([])
  const [oldImages, setOldImages] = useState([])
  const [latitude, setLatitude] = useState(49.288219)
  const [longitude, setLongitude] = useState(-123.115723)
  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [cityId, setCityId] = useState()
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [video, setVideo] = useState([])
  const [newVideos, setNewVideos] = useState([])
  const [propertyImages, setPropertyImages] = useState([])

  useEffect(() => {
    if (!countries.length) {
      dispatch(actions.GetCountries())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (countries && countries.length && !states.length) {
      setStates(countries[0])
      setCities(countries[0].states[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  useEffect(() => {
    if (
      _.isEmpty(form1Data) &&
      singlePropertyData &&
      !_.isEmpty(singlePropertyData)
    ) {
      setAddress(singlePropertyData.address ?? '')
      setState(singlePropertyData.state ?? 'Ontario')
      setCity(singlePropertyData.city ?? 'Toronto')
      setCityId(singlePropertyData.cityId ?? 17121)
      setLongitude(singlePropertyData.longitude ?? -79.25622)
      setLatitude(singlePropertyData.latitude ?? 43.703712)
      setOldImages(singlePropertyData.imageList ?? [])
    } else {
      setAddress(form1Data.address ?? '')
      setLongitude(form1Data.longitude ?? -79.25622)
      setLatitude(form1Data.latitude ?? 43.703712)
      setState(form1Data.state ?? 'Ontario')
      setCity(form1Data.city ?? 'Toronto')
      setCityId(form1Data.cityId ?? 17121)
      setOldImages(form1Data.oldImages ?? [])
      setNewVideos(form1Data.newVideos ?? [])
      setPreviewImages(form1Data.previewImages ?? [])
    }
  }, [form1Data, singlePropertyData])

  const searchCity = (address, cities) => {
    for (let i = 0; i < cities?.cities?.length ?? 0; i++) {
      if (cities.cities[i].name === address) {
        return cities.cities[i]
      }
    }
  }

  const handleChange = (addr) => {
    setAddress(addr)
  }

  const handleSelect = (addr) => {
    const add1 = addr.split(', ')
    const cityName = add1[1]
    const updatedCity = searchCity(cityName, cities)
    if (updatedCity) {
      setAddress(addr)
      setCity(updatedCity)
    } else {
      modelHanlder(
        'alertPopup',
        'We are currently available in cities of Ontario Canada.',
      )
      setAddress('')
      // alert("Sorry! We are currently available in cities of Ontario Canada.")
    }

    geocodeByAddress(addr)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLongitude(latLng.lng)
        setLatitude(latLng.lat)
        console.log('Success', latLng)
      })
      .catch((error) => console.error('Error', error))
  }

  const onDrop = (files) => {
    const updatedPreviewImages = _.cloneDeep(previewImages)
    const updatedVideos = _.cloneDeep(newVideos)
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.indexOf('video') > -1 && newVideos.length < 1) {
        const index = oldImages.findIndex(
          (data) => data.imageURL.indexOf('.mp4') > -1,
        )
        if (index < 0) {
          updatedVideos.push(files[i])
        }
      } else if (files[i].type.indexOf('image') > -1) {
        updatedPreviewImages.push(files[i])
      }
    }
    setNewVideos(updatedVideos)
    setPreviewImages(updatedPreviewImages)
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const imageRemoveHandler = (index) => {
    if (previewImages && previewImages.length > index) {
      const filteredImages = previewImages.filter((image, idx) => idx !== index)
      setPreviewImages(filteredImages)
    }
  }

  const videoRemoveHandler = (index) => {
    if (newVideos && newVideos.length > index) {
      const filteredVideos = newVideos.filter((video, idx) => idx !== index)
      setNewVideos(filteredVideos)
    }
  }

  const oldVideoRemoveHandler = (url) => {
    if (oldImages && oldImages.length) {
      const filteredImages = oldImages.filter((data) => data.imageURL !== url)
      const updatedVideos = [
        ...video,
        {
          newVideoUrl: '',
          existingVideoUrl: url,
          action: 'delete',
        },
      ]
      setOldImages(filteredImages)
      setVideo(updatedVideos)
    }
  }

  const oldImageRemoveHandler = (url) => {
    if (oldImages && oldImages.length) {
      const filteredImages = oldImages.filter((data) => data.imageURL !== url)
      const updatedImages = [
        ...propertyImages,
        {
          image: '',
          existingImagesUrl: url,
          action: 'delete',
        },
      ]
      setOldImages(filteredImages)
      setPropertyImages(updatedImages)
    }
  }

  const onChange = (e) => {
    if (e.target.name === 'country') {
      let index = 0
      if (e.target.value !== '' && countries) {
        index = countries.findIndex((x) => `${x.name}` === e.target.value)
      }
      setStates(cloneDeep(countries[index]))
      setCountry(e.target.value)
    } else if (e.target.name === 'state') {
      let ind = 0
      if (e.target.value !== '' && states) {
        ind = states.findIndex((x) => `${x.name}` === e.target.value)
      }
      setCities(cloneDeep(states[ind]))
      setState(e.target.value)
    } else if (e.target.name === 'previewImages') {
      const updatedPreviewImages = _.cloneDeep(previewImages)
      const updatedVideos = _.cloneDeep(newVideos)
      for (let i = 0; i < e.target.files.length; i++) {
        if (
          e.target.files[i].type.indexOf('video') > -1 &&
          newVideos.length < 1
        ) {
          const index = oldImages.findIndex(
            (data) => data.imageURL.indexOf('.mp4') > -1,
          )
          if (index < 0) {
            updatedVideos.push(e.target.files[i])
          }
        } else if (e.target.files[i].type.indexOf('image') > -1) {
          updatedPreviewImages.push(e.target.files[i])
        }
      }
      setNewVideos(updatedVideos)
      setPreviewImages(updatedPreviewImages)
    }
  }

  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  }

  const imagesHandler = (imagesParam) => {
    let finalImages = []
    if (imagesParam.length) {
      for (let i = 0; i < imagesParam.length; i++) {
        getBase64(imagesParam[i], (cb) => {
          let updateImage = cb.split(',')[1]
          finalImages.push({ image: updateImage })
          if (i === imagesParam.length - 1) {
            addPropertyHandler(finalImages)
          }
        })
      }
    } else {
      addPropertyHandler([])
    }
  }

  const addPropertyHandler = (imagesParam) => {
    const dataForm1 = {
      address: address,
      state: state,
      images: imagesParam,
      newVideos,
      video,
      oldImages,
      city: city.name ?? city,
      cityId: city.id ?? cityId,
      propertyImages,
      previewImages: previewImages,
      latitude: latitude,
      longitude: longitude,
    }
    const totalImages = oldImages.length + previewImages.length
    const isVideo = oldImages.findIndex(
      (data) => data.imageURL.indexOf('.mp4') > -1,
    )
    if (isVideo > -1 || newVideos.length || totalImages >= 5) {
      form1DataHandler(dataForm1)
      formShowHandler(1)
    } else {
      alert('Property Images Must be 5 or more')
    }
  }

  const imageOrVideoRenderer = (url, index) => {
    let renderer = ''
    if (url.indexOf('.mp4') > -1) {
      const videoUrl = url.replace(
        'https://sample-video-app-hu.herokuapp.com',
        'https://houseup-streaming-service.glitch.me',
      )
      renderer = (
        <span key={index} style={{ position: 'relative' }}>
          <i
            style={{ position: 'absolute', right: '0', cursor: 'pointer' }}
            className="fas fa-minus-circle"
            onClick={() => oldVideoRemoveHandler(url)}
          ></i>
          <video
            key={index}
            id="videoPlayer"
            style={{
              maxWidth: '100%',
              height: '98px',
              verticalAlign: 'middle',
              paddingRight: '5px',
            }}
            controls
            muted="muted"
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </span>
      )
    } else {
      renderer = (
        <span key={index} style={{ position: 'relative' }}>
          <i
            style={{ position: 'absolute', right: '0', cursor: 'pointer' }}
            className="fas fa-minus-circle"
            onClick={() => oldImageRemoveHandler(url)}
          ></i>
          <img
            id="data"
            src={url}
            alt=""
            style={{ height: '98px', paddingRight: '5px' }}
          />
        </span>
      )
    }
    return renderer
  }

  const onSubmit = (e) => {
    e.preventDefault()
    imagesHandler(previewImages)
  }

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div
          className="add-property-conatiner"
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div className="row border-property">
            <div className="col-md-12">
              <h1 className="titles-property">List your property</h1>
              <p className="pairing-industry">
                Pairing the industry's top technology with unsurpassed local
                expertise.
              </p>
              <Nav variant="pills" defaultActiveKey="/1">
                <Nav.Item>
                  <Nav.Link
                    eventKey="/1"
                    className="tabs"
                    onClick={() => formShowHandler(0)}
                  >
                    Step 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/2"
                    className="tabs"
                    onClick={() => formShowHandler(1)}
                  >
                    Step 2
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/3"
                    className="tabs"
                    onClick={() => formShowHandler(2)}
                  >
                    Step 3
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="form-group">
                    <input
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className:
                          'location-search-input input-feilds-property',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              position: 'relative',
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                            }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div
              className="col-md-12"
              style={{ height: '300px', width: '100%' }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={{
                  lat: latitude,
                  lng: longitude,
                }}
                center={{
                  lat: latitude,
                  lng: longitude,
                }}
                defaultZoom={defaultProps.zoom}
              >
                <Marker lat={latitude} lng={longitude} />
              </GoogleMapReact>
            </div>
          </div>
          <div className="row border-property">
            <h1
              className="col-md-6 titles-property"
              style={{ fontFamily: 'light', marginTop: '50px' }}
            >
              Property photos
            </h1>
            <div className="col-md-6" style={{ textAlign: 'right' }}>
              <label
                className="btn btn-lg btn-primary"
                htmlFor="pictures"
                style={{
                  marginTop: '50px',
                  backgroundColor: '#00B0E9',
                  borderRadius: '0px',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Upload images
              </label>
            </div>
            <h6 className="col-md-12 text-danger titles-property">
              WARNING: Any images with HouseUp.ca watermarks are a violation of
              copyright. If these images are uploaded your listing will be
              removed and your account may be suspended.
            </h6>
            <div className="col-12">
              <Dropzone onDrop={onDrop} className="drop-zone">
                {({ getRootProps, getInputProps }) => (
                  <section className="container drop-zone">
                    <aside>
                      {previewImages && previewImages.length
                        ? previewImages.map((data, index) => (
                            <span key={index} style={{ position: 'relative' }}>
                              <i
                                style={{
                                  position: 'absolute',
                                  right: '0',
                                  cursor: 'pointer',
                                }}
                                className="fas fa-minus-circle"
                                onClick={() => imageRemoveHandler(index)}
                              ></i>
                              <img
                                id="data"
                                src={
                                  data
                                    ? URL.createObjectURL(data)
                                    : require('../../../assets/images/ic_profile_placeholder.png')
                                }
                                alt=""
                                style={{ height: '98px' }}
                              />
                            </span>
                          ))
                        : ''}
                      {newVideos && newVideos.length
                        ? newVideos.map((data, index) => (
                            <span style={{ position: 'relative' }}>
                              <i
                                style={{
                                  position: 'absolute',
                                  right: '0',
                                  cursor: 'pointer',
                                }}
                                className="fas fa-minus-circle"
                                onClick={() => videoRemoveHandler(index)}
                              ></i>
                              <video
                                key={index}
                                id="videoPlayer"
                                style={{
                                  maxWidth: '100%',
                                  height: '98px',
                                  verticalAlign: 'middle',
                                  paddingRight: '5px',
                                }}
                                controls
                                muted="muted"
                              >
                                <source
                                  src={URL.createObjectURL(data)}
                                  type="video/mp4"
                                />
                              </video>
                            </span>
                          ))
                        : ''}
                      {oldImages && oldImages.length
                        ? oldImages.map((data, index) =>
                            imageOrVideoRenderer(data.imageURL, index),
                          )
                        : ''}
                    </aside>
                    <div
                      {...getRootProps({
                        className: 'dropzone , drop-zone-inner',
                      })}
                    >
                      <input
                        type="file"
                        {...getInputProps()}
                        id="pictures"
                        name="previewImages"
                        onChange={onChange}
                      />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>

              <h6
                className="titles-property"
                style={{
                  color: '#8E8E93',
                  marginTop: '8px',
                  fontSize: '12px',
                }}
              >
                Upload a maximum of 25 photos. Click on a picture to select a
                cover photo, otherwise the first picture will be used.
              </h6>
            </div>
          </div>
          <div className="row mb-40">
            <div className="col-md-6" />
            <div className="col-md-6">
              <div className="btn-div">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary btn-property"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}
