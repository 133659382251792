import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import _ from 'lodash'
import { validateIsNumber } from '../../../utils'

export default ({
  dropDownData,
  form3Data,
  singlePropertyData,
  form3DataHandler,
  formShowHandler,
  loading,
}) => {
  const [propertyTypeId, setPropertyTypeId] = useState(1)
  const [rentalListingYN, setRentalListingYN] = useState('')
  const [buildingTypeId, setBuildingTypeId] = useState(1)
  const [noOfBedrooms, setNoOfBedrooms] = useState(1)
  const [noOfBathrooms, setNoOfBathrooms] = useState(1)
  const [finishedSqftArea, setFinishedSqftArea] = useState(0)
  const [lotDimensionLength, setLotDimensionLength] = useState(0)
  const [lotDimensionWidth, setLotDimensionWidth] = useState(0)
  const [lotTotalArea, setLotTotalArea] = useState(0)
  const [areaTypeId, setAreaTypeId] = useState(1)
  const [yearBuilt, setYearBuilt] = useState(0)
  const [basementId, setBasementId] = useState(1)
  const [garageId, setGarageId] = useState(1)
  const [parkingSpaces, setParkingSpaces] = useState(1)
  const [condoFee, setCondoFee] = useState(0)

  const [moreInfo, setMoreInfo] = useState(false)
  const [primaryHeatingFuelId, setPrimaryHeatingFuelId] = useState(1)
  const [yearFurnaceBuilt, setYearFurnaceBuilt] = useState(0)
  const [yearRoofInstalled, setYearRoofInstalled] = useState(0)
  const [storeys, setStoreys] = useState(1)
  const [waterSourceID, setWaterSourceID] = useState(1)

  const [internet, setInternet] = useState(false)
  const [garage, setGarage] = useState(false)
  const [ac, setAc] = useState(false)
  const [dishWasher, setDishWasher] = useState(false)
  const [disposal, setDisposal] = useState(false)
  const [balcony, setBalcony] = useState(false)
  const [gym, setGym] = useState(false)
  const [playroom, setPlayroom] = useState(false)
  const [bar, setBar] = useState(false)

  const [propertyType, setPropertyType] = useState([])
  const [buildingType, setBuildingType] = useState([])
  const [bedroomCount, setBedroomCount] = useState([])
  const [bathroomCount, setBathroomCount] = useState([])
  const [areaType, setAreaType] = useState([])
  const [basementType, setBasementType] = useState([])
  const [garageType, setGarageType] = useState([])
  const [parkingSpace, setParkingSpace] = useState([])

  const [primaryHeatingFuel, setPrimaryHeatingFuel] = useState([])
  const [storeysCount, setStoreysCount] = useState([])
  const [waterSource, setWaterSource] = useState([])

  const [amenites, setAmenites] = useState([])

  useEffect(() => {
    if (dropDownData && !_.isEmpty(dropDownData)) {
      setPropertyType(dropDownData.propertyType ?? [])
      setBuildingType(dropDownData.buildingType ?? [])
      setBedroomCount(dropDownData.bedroomCount ?? [])
      setBathroomCount(dropDownData.bathroomCount ?? [])
      setAreaType(dropDownData.areaType ?? [])
      setBasementType(dropDownData.basementType ?? [])
      setGarageType(dropDownData.garageType ?? [])
      setParkingSpace(dropDownData.parkingSpaces ?? [])
      setPrimaryHeatingFuel(dropDownData.primaryHeatingFuel ?? [])
      setWaterSource(dropDownData.waterSource ?? [])
      setStoreysCount(dropDownData.storeysCount ?? [])
    }
  }, [dropDownData])

  useEffect(() => {
    if (
      _.isEmpty(form3Data) &&
      singlePropertyData &&
      !_.isEmpty(singlePropertyData)
    ) {
      setPropertyTypeId(singlePropertyData.propertyTypeId ?? 1)
      setRentalListingYN(singlePropertyData.rentalListingYN ?? 'Yes')
      setBuildingTypeId(singlePropertyData.buildingTypeId ?? 1)
      setNoOfBedrooms(singlePropertyData.noOfBedrooms ?? 1)
      setNoOfBathrooms(singlePropertyData.noOfBathrooms ?? 1)
      setFinishedSqftArea(singlePropertyData.finishedSqftArea ?? 0)
      setLotDimensionLength(singlePropertyData.lotDimensionLength ?? 0)
      setLotDimensionWidth(singlePropertyData.lotDimensionWidth ?? 0)
      setLotTotalArea(singlePropertyData.lotTotalArea ?? 0)
      setAreaTypeId(singlePropertyData.areaTypeId ?? 1)
      setYearBuilt(singlePropertyData.yearBuilt ?? 0)
      setBasementId(singlePropertyData.basementId ?? 1)
      setGarageId(singlePropertyData.garageId ?? 1)
      setParkingSpaces(singlePropertyData.parkingSpaces ?? 1)
      setCondoFee(singlePropertyData.condoFee ?? 0)
      setPrimaryHeatingFuelId(singlePropertyData.primaryHeatingFuelId ?? 1)
      setYearFurnaceBuilt(singlePropertyData.yearFurnaceBuilt ?? 0)
      setYearRoofInstalled(singlePropertyData.yearRoofInstalled ?? 0)
      setStoreys(singlePropertyData.storeys ?? 1)
      setWaterSourceID(singlePropertyData.waterSourceID ?? 1)

      setInternet(singlePropertyData.amenites?.internet ?? false)
      setGarage(singlePropertyData.amenites.garage ?? false)
      setAc(singlePropertyData.amenites.ac ?? false)
      setDishWasher(singlePropertyData.amenites.dishWasher ?? false)
      setDisposal(singlePropertyData.amenites.disposal ?? false)
      setBalcony(singlePropertyData.amenites.balcony ?? false)
      setGym(singlePropertyData.amenites?.gym ?? false)
      setPlayroom(singlePropertyData.amenites.playroom ?? false)
      setBar(singlePropertyData.amenites.bar ?? false)
      setAmenites(singlePropertyData.amenites ?? [])
    } else {
      setPropertyTypeId(form3Data.propertyTypeId ?? 1)
      setRentalListingYN(form3Data.rentalListingYN ?? 'Yes')
      setBuildingTypeId(form3Data.buildingTypeId ?? 1)
      setNoOfBedrooms(form3Data.noOfBedrooms ?? 1)
      setNoOfBathrooms(form3Data.noOfBathrooms ?? 1)
      setFinishedSqftArea(form3Data.finishedSqftArea ?? 0)
      setLotDimensionLength(form3Data.lotDimensionLength ?? 0)
      setLotDimensionWidth(form3Data.lotDimensionWidth ?? 0)
      setLotTotalArea(form3Data.lotTotalArea ?? 0)
      setAreaTypeId(form3Data.areaTypeId ?? 1)
      setYearBuilt(form3Data.yearBuilt ?? 0)
      setBasementId(form3Data.basementId ?? 1)
      setGarageId(form3Data.garageId ?? 1)
      setParkingSpaces(form3Data.parkingSpaces ?? 1)
      setCondoFee(form3Data.condoFee ?? 0)
      setPrimaryHeatingFuelId(form3Data.primaryHeatingFuelId ?? 1)
      setYearFurnaceBuilt(form3Data.yearFurnaceBuilt ?? 0)
      setYearRoofInstalled(form3Data.yearRoofInstalled ?? 0)
      setStoreys(form3Data.storeys ?? 1)
      setWaterSourceID(form3Data.waterSourceID ?? 1)

      setInternet(form3Data.internet ?? false)
      setGarage(form3Data.garage ?? false)
      setAc(form3Data.ac ?? false)
      setDishWasher(form3Data.dishWasher ?? false)
      setDisposal(form3Data.disposal ?? false)
      setBalcony(form3Data.balcony ?? false)
      setGym(form3Data.gym ?? false)
      setPlayroom(form3Data.playroom ?? false)
      setBar(form3Data.bar ?? false)
      setAmenites(form3Data.amenites ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form3Data, singlePropertyData])

  const onChange = (targetName) => (e) => {
    const { value } = e.target
    if (targetName === 'lotDimensionLength' && validateIsNumber(value)) {
      setLotDimensionLength(Number(value))
      setLotTotalArea(Number(value) * lotDimensionWidth)
    } else if (targetName === 'lotDimensionWidth' && validateIsNumber(value)) {
      setLotDimensionWidth(Number(value))
      setLotTotalArea(Number(value) * lotDimensionLength)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const dataForm3 = {
      propertyTypeId,
      rentalListingYN,
      buildingTypeId,
      noOfBedrooms,
      noOfBathrooms,
      finishedSqftArea,
      lotDimensionLength,
      lotDimensionWidth,
      lotTotalArea,
      areaTypeId,
      yearBuilt,
      basementId,
      garageId,
      parkingSpaces,
      condoFee,
      primaryHeatingFuelId,
      yearFurnaceBuilt,
      yearRoofInstalled,
      storeys,
      waterSourceID,

      internet,
      garage,
      ac,
      dishWasher,
      disposal,
      balcony,
      gym,
      playroom,
      bar,
      amenites,
    }

    // if (propertyType && propertyType.length && !propertyTypeId) {
    // 	setPropertyTypeId(propertyType[0].id);
    // }
    // if (parkingSpace && parkingSpace.length && !noOfBedrooms) {
    // 	setNoOfBedrooms(parkingSpace[0].id);
    // }
    // if (bedroomCount && bedroomCount.length && !bedroomCount) {
    // 	setPropertyTypeId(bedroomCount[0].id);
    // }
    // if (bathroomCount && bathroomCount.length && !noOfBathrooms) {
    // 	setNoOfBathrooms(bathroomCount[0].id);
    // }
    // if (basementType && basementType.length && !basementId) {
    // 	setBasementId(basementType[0].id);
    // }
    // if (garageType && garageType.length && !garageId) {
    // 	setGarageId(garageType[0].id);
    // }
    // if (
    // 	primaryHeatingFuel &&
    // 	primaryHeatingFuel.length &&
    // 	!primaryHeatingFuelId
    // ) {
    // 	setPrimaryHeatingFuelId(primaryHeatingFuel[0].id);
    // }
    // if (waterSource && waterSource.length && !waterSourceID) {
    // 	setWaterSourceID(waterSource[0].id);
    // }
    // if (storeysCount && storeysCount.length && !storeys) {
    // 	setStoreys(storeysCount[0].id);
    // }
    // if (areaType && areaType.length && !areaTypeId) {
    // 	setAreaTypeId(areaType[0].id);
    // }
    // if (buildingType && buildingType.length && !buildingTypeId) {
    // 	setBuildingTypeId(buildingType[0].id);
    // }
    form3DataHandler(dataForm3)
  }

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div
          className="add-property-conatiner"
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div className="row border-property">
            <div className="col-md-12">
              <h1 className="titles-property">List your property</h1>
              <p className="pairing-industry">
                Pairing the industry's top technology with unsurpassed local
                expertise.
              </p>
              <Nav variant="pills" defaultActiveKey="/3">
                <Nav.Item>
                  <Nav.Link
                    eventKey="/1"
                    className="tabs"
                    onClick={() => formShowHandler(0)}
                  >
                    Step 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/2"
                    className="tabs"
                    onClick={() => formShowHandler(1)}
                  >
                    Step 2
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/3"
                    className="tabs"
                    onClick={() => formShowHandler(2)}
                  >
                    Step 3
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>

          <div className="row border-property">
            <div className="col-md-4">
              <h6 className="titles-property">*Property type</h6>
              <select
                className="input-feilds-property"
                name="propertyTypeId"
                onChange={(e) => setPropertyTypeId(Number(e.target.value))}
                value={propertyTypeId}
                required
              >
                {propertyType && propertyType.length
                  ? propertyType.map((data, idx) => (
                      <option key={idx} value={data.id}>
                        {data.value}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
            <div className="col-md-4">
              <h6 className="titles-property" required>
                Rent/Sell
              </h6>
              <select
                className="input-feilds-property"
                name="rentalListingYN"
                onChange={(e) => setRentalListingYN(e.target.value)}
                value={rentalListingYN}
              >
                <option value=""> --- </option>
                <option value="No">Sell</option>
                <option value="Yes">Rent </option>
              </select>
            </div>
          </div>
          {propertyTypeId === '2' ? (
            <div className="row border-property">
              <div className="col-md-4">
                <h6 className="titles-property">Bedrooms</h6>
                <select
                  className="input-feilds-property"
                  name="noOfBedrooms"
                  onChange={(e) => setNoOfBedrooms(Number(e.target.value))}
                  value={noOfBedrooms}
                  required
                >
                  {bedroomCount && bedroomCount.length
                    ? bedroomCount.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Bathrooms</h6>
                <select
                  className="input-feilds-property"
                  name="noOfBathrooms"
                  onChange={(e) => setNoOfBedrooms(Number(e.target.value))}
                  value={noOfBathrooms}
                  required
                >
                  {bathroomCount && bathroomCount.length
                    ? bathroomCount.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">*Finishes square feet</h6>
                <input
                  type="text"
                  className="input-feilds-property"
                  name="finishedSqftArea"
                  onChange={(e) =>
                    validateIsNumber(e.target.value) &&
                    setFinishedSqftArea(Number(e.target.value))
                  }
                  value={finishedSqftArea}
                  required
                />
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Year built</h6>
                <input
                  className="input-feilds-property"
                  name="yearBuilt"
                  onChange={(e) => setYearBuilt(Number(e.target.value))}
                  value={yearBuilt}
                  type="number"
                  required
                />
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Parking spaces</h6>
                <select
                  className="input-feilds-property"
                  name="parkingSpaces"
                  onChange={(e) => setParkingSpaces(Number(e.target.value))}
                  value={parkingSpaces}
                  required
                >
                  {parkingSpace && parkingSpace.length
                    ? parkingSpace.map((data, idx) => (
                        <option key={idx} value={data.value}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Condo fees (/month)</h6>
                <input
                  className="input-feilds-property"
                  name="condoFee"
                  value={condoFee}
                  type="text"
                  onChange={(e) =>
                    validateIsNumber(e.target.value) &&
                    setCondoFee(Number(e.target.value))
                  }
                  required
                />
              </div>
            </div>
          ) : (
            <div className="row border-property">
              <div className="col-md-4">
                <h6 className="titles-property">Building type</h6>
                <select
                  className="input-feilds-property"
                  name="buildingTypeId"
                  onChange={(e) => setBuildingTypeId(Number(e.target.value))}
                  value={buildingTypeId}
                  required
                >
                  {buildingType && buildingType.length
                    ? buildingType.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Bedrooms</h6>
                <select
                  className="input-feilds-property"
                  name="noOfBedrooms"
                  onChange={(e) => setNoOfBedrooms(Number(e.target.value))}
                  value={noOfBedrooms}
                  required
                >
                  {bedroomCount && bedroomCount.length
                    ? bedroomCount.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Bathrooms</h6>
                <select
                  className="input-feilds-property"
                  name="noOfBathrooms"
                  onChange={(e) => setNoOfBathrooms(Number(e.target.value))}
                  value={noOfBathrooms}
                  required
                >
                  {bathroomCount && bathroomCount.length
                    ? bathroomCount.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">*Finishes square feet</h6>
                <input
                  type="text"
                  className="input-feilds-property"
                  name="finishedSqftArea"
                  onChange={(e) =>
                    validateIsNumber(e.target.value) &&
                    setFinishedSqftArea(Number(e.target.value))
                  }
                  value={finishedSqftArea}
                  required
                />
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Lot dimensions (feet)</h6>
                <input
                  className="multiply-input"
                  name="lotDimensionLength"
                  value={lotDimensionLength}
                  onChange={onChange('lotDimensionLength')}
                  required
                />
                <span className="multiply">x</span>
                <input
                  className="multiply-input"
                  name="lotDimensionWidth"
                  value={lotDimensionWidth}
                  onChange={onChange('lotDimensionWidth')}
                  required
                />
              </div>
              <div className="col-md-2">
                <h6 className="titles-property">Lot area</h6>
                <input
                  className="input-feilds-property"
                  name="lotTotalArea"
                  value={lotTotalArea}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <h6 className="titles-property">* Sqft/Acres</h6>
                <select
                  className="input-feilds-property"
                  name="areaTypeId"
                  value={areaTypeId}
                  onChange={(e) => setAreaTypeId(Number(e.target.value))}
                  required
                >
                  {areaType && areaType.length
                    ? areaType.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Year built</h6>
                <input
                  className="input-feilds-property"
                  type="number"
                  name="yearBuilt"
                  value={yearBuilt}
                  onChange={(e) => setYearBuilt(Number(e.target.value))}
                  required
                />
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Basement</h6>
                <select
                  className="input-feilds-property"
                  name="basementId"
                  value={basementId}
                  onChange={(e) => setBasementId(Number(e.target.value))}
                  required
                >
                  {basementType && basementType.length
                    ? basementType.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-4">
                <h6 className="titles-property">Garage</h6>
                <select
                  className="input-feilds-property"
                  name="garageId"
                  value={garageId}
                  onChange={(e) => setGarageId(Number(e.target.value))}
                  required
                >
                  {garageType && garageType.length
                    ? garageType.map((data, idx) => (
                        <option key={idx} value={data.id}>
                          {data.value}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="more-options"
                  onClick={() => setMoreInfo(!moreInfo)}
                >
                  More Options
                </button>
              </div>
              {moreInfo ? (
                <>
                  <div className="col-md-4">
                    <h6 className="titles-property">Primary heating fuel</h6>
                    <select
                      className="input-feilds-property"
                      name="primaryHeatingFuelId"
                      required
                      value={primaryHeatingFuelId}
                      onChange={(e) =>
                        setPrimaryHeatingFuelId(Number(e.target.value))
                      }
                    >
                      {primaryHeatingFuel && primaryHeatingFuel.length
                        ? primaryHeatingFuel.map((data, idx) => (
                            <option key={idx} value={data.id}>
                              {data.value}
                            </option>
                          ))
                        : ''}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <h6 className="titles-property">Year furnace installed</h6>
                    <input
                      className="input-feilds-property"
                      type="text"
                      required
                      name="yearFurnaceBuilt"
                      value={yearFurnaceBuilt}
                      onChange={(e) =>
                        validateIsNumber(e.target.value) &&
                        setYearFurnaceBuilt(Number(e.target.value))
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <h6 className="titles-property">Year roof installed</h6>
                    <input
                      className="input-feilds-property"
                      type="text"
                      required
                      name="yearRoofInstalled"
                      value={yearRoofInstalled}
                      onChange={(e) =>
                        validateIsNumber(e.target.value) &&
                        setYearRoofInstalled(Number(e.target.value))
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <h6 className="titles-property">Storeys</h6>
                    <select
                      className="input-feilds-property"
                      name="storeys"
                      value={storeys}
                      onChange={(e) => setStoreys(Number(e.target.value))}
                      required
                    >
                      {storeysCount && storeysCount.length
                        ? storeysCount.map((data, idx) => (
                            <option key={idx} value={data.id}>
                              {data.value}
                            </option>
                          ))
                        : ''}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <h6 className="titles-property">Water source</h6>
                    <select
                      className="input-feilds-property"
                      name="waterSourceID"
                      value={waterSourceID}
                      onChange={(e) => setWaterSourceID(Number(e.target.value))}
                      required
                    >
                      {waterSource && waterSource.length
                        ? waterSource.map((data, idx) => (
                            <option key={idx} value={data.id}>
                              {data.value}
                            </option>
                          ))
                        : ''}
                    </select>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          )}
          <div>
            <h3
              style={{
                color: '#000000',
                fontFamily: 'light',
                fontSize: '32px',
              }}
            >
              Amenities
            </h3>
            <div className="row mt-3 mt-md-4">
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="internet"
                        value={internet}
                        checked={internet}
                        onChange={() => setInternet(!internet)}
                      />
                      <span className="fa fa-wifi" /> Internet
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="garage"
                        value={garage}
                        checked={garage}
                        onChange={() => setGarage(!garage)}
                      />
                      <span className="fa fa-car" /> Garage
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="ac"
                        value={ac}
                        checked={ac}
                        onChange={() => setAc(!ac)}
                      />
                      <span className="fa fa-sun" /> Air Conditioning
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="dishWasher"
                        checked={dishWasher}
                        value={dishWasher}
                        onChange={() => setDishWasher(!dishWasher)}
                      />
                      <span className="fa fa-bullseye" /> Dishwasher
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="disposal"
                        checked={disposal}
                        value={disposal}
                        onChange={() => setDisposal(!disposal)}
                      />
                      <span className="fa fa-recycle" /> Disposal
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="balcony"
                        checked={balcony}
                        value={balcony}
                        onChange={() => setBalcony(!balcony)}
                      />
                      <span className="fa fa-clone" /> Balcony
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="gym"
                        checked={gym}
                        value={gym}
                        onChange={() => setGym(!gym)}
                      />
                      <span className="fa fa-futbol" /> Gym
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="playroom"
                        checked={playroom}
                        value={playroom}
                        onChange={() => setPlayroom(!playroom)}
                      />
                      <span className="fa fa-smile" /> Playroom
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <div className="checkbox custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="bar"
                        checked={bar}
                        value={bar}
                        onChange={() => setBar(!bar)}
                      />
                      <span className="fa fa-glass" /> Bar
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="btn-div-prev">
                <button
                  className="btn btn-lg btn-primary btn-property"
                  onClick={() => formShowHandler(1)}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-div">
                <button
                  type="submit"
                  disabled={loading ? true : false}
                  className="btn btn-lg btn-primary btn-property"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}
