import React from 'react'

export const limitWordHandler = (str, maxLimit) => {
  const arrayString = str.split(' ')
  let paragraph = ''
  const limit = arrayString.length < maxLimit ? arrayString.length : maxLimit
  for (let i = 0; i < limit; i++) {
    paragraph += arrayString[i] + ' '
  }
  if (arrayString.length >= 3) {
    paragraph += '...'
  }
  return paragraph
}

export const dateHandler = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return (
    <strong className="h5 mb-0">
      {date.split('-')[2]}
      <sup className="smaller text-gray font-weight-normal">
        {months[date.split('-')[1] - 1]}
      </sup>
    </strong>
  )
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateIsNumber = (value) => {
  const regex = /^\d*$/
  return regex.test(value)
}
