import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../store/actions/index'
import { Alert } from 'react-bootstrap'
import _ from 'lodash'

import Form1 from './UpdateProperty/form1'
import Form2 from './UpdateProperty/form2'
import Form3 from './UpdateProperty/form3'
import Spinner from '../../components/common/Spinner'

export default ({ closeCodelHanlder, modelHanlder }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { propertyId } = useParams()
  const { dropDownData, singlePropertyData, loading } = useSelector(
    (state) => state.property,
  )
  const { user } = useSelector((state) => state.auth)
  const errors = useSelector((state) => state.errors)

  const [formShow, setFormShow] = useState(0)
  const [form1Data, setForm1Data] = useState({})
  const [form2Data, setForm2Data] = useState({})
  const [form3Data, setForm3Data] = useState({})

  useEffect(() => {
    if (!dropDownData || !dropDownData.currencies) {
      dispatch(actions.dropDwonMenu())
    }
    let reqPacket = {
      propertyId: Number(propertyId),
    }
    dispatch(actions.getSingleProperty(reqPacket))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(form3Data)) {
      updateProperty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form3Data])

  const form1DataHandler = (form1DataParam) => {
    setForm1Data(form1DataParam)
  }

  const form2DataHandler = (form2DataParam) => {
    setForm2Data(form2DataParam)
  }

  const form3DataHandler = (form3DataParam) => {
    setForm3Data(form3DataParam)
  }

  const updateProperty = () => {
    let imageUpdate = false
    let videoUpdate = false
    const propertyImages = _.cloneDeep(form1Data.propertyImages)
    if (form1Data.images && form1Data.images.length) {
      imageUpdate = true
      form1Data.images.map((image) => {
        const imageData = {
          image: image.image,
          existingImagesUrl: '',
          action: 'add',
        }
        propertyImages.push(imageData)
        return image
      })
    }
    if (form1Data.newVideos?.length) {
      videoUpdate = true
    }

    const formData = {
      latitude: form1Data.latitude ?? 43.703712,
      longitude: form1Data.longitude ?? -79.25622,
      city: form1Data.city ?? 'Toronto',
      address: form1Data.address ?? '',
      cityId: form1Data?.cityId ?? 17121,
      state: form1Data.state ?? 'Ontario',
      propertyImages,
      video: form1Data.video,
      imageUpdate,
      videoUpdate,

      currencyId: form2Data.currencyId ?? 2,
      description: form2Data.description ?? '',
      contactEmail: form2Data.contactEmail ?? '',
      adTitle: form2Data.adTitle ?? '',
      contactName: form2Data.contactName ?? '',
      contactNumber: form2Data.contactNumber ?? 0,
      userId: form2Data.userId ?? 0,
      price: form2Data.price ?? 0,

      propertyTypeId: form3Data.propertyTypeId ?? 1,
      rentalListingYN: form3Data.rentalListingYN ?? 'Yes',
      buildingTypeId: form3Data.buildingTypeId ?? 1,
      noOfBedrooms: form3Data.noOfBedrooms ?? 1,
      noOfBathrooms: form3Data.noOfBathrooms ?? 1,
      finishedSqftArea: form3Data.finishedSqftArea ?? 0,
      lotDimensionLength: form3Data.lotDimensionLength ?? 0,
      lotDimensionWidth: form3Data.lotDimensionWidth ?? 0,
      lotTotalArea: form3Data.lotTotalArea ?? 0,
      areaTypeId: form3Data.areaTypeId ?? 1,
      yearBuilt: form3Data.yearBuilt ?? 0,
      basementId: form3Data.basementId ?? 1,
      garageId: form3Data.garageId ?? 1,
      parkingSpaces: form3Data.parkingSpaces ?? 1,
      condoFee: `${form3Data.condoFee ?? 0}`,
      primaryHeatingFuelId: form3Data.primaryHeatingFuelId ?? 1,
      yearFurnaceBuilt: form3Data.yearFurnaceBuilt ?? 0,
      yearRoofInstalled: form3Data.yearRoofInstalled ?? 0,
      storeys: form3Data.storeys ?? 1,
      waterSourceID: form3Data.waterSourceID ?? 1,

      internet: form3Data.internet ?? false,
      garage: form3Data.garage ?? false,
      ac: form3Data.ac ?? false,
      dishWasher: form3Data.dishWasher ?? false,
      disposal: form3Data.disposal ?? false,
      balcony: form3Data.balcony ?? false,
      gym: form3Data.gym ?? false,
      playroom: form3Data.playroom ?? false,
      bar: form3Data.bar ?? false,
      amenites: form3Data.amenites,

      propertyId: singlePropertyData.propertId ?? 0,
      phoneNo: user.msisdn ?? '',
      country: 'Canada',
      channel: 'web',
      images: null,
      action: '',
      propertyFeeId: '0',
      packageRenewal: false,
    }
    if (form1Data.newVideos && form1Data.newVideos.length) {
      dispatch(
        actions.uploadVideo(
          form1Data.newVideos[0],
          formData,
          history,
          closeCodelHanlder,
        ),
      )
    } else {
      dispatch(actions.UpdateProperty(formData, history, closeCodelHanlder))
    }
  }

  const formShowHandler = (num) => {
    setFormShow(num)
  }

  let pageContent = ''

  if (loading) {
    pageContent = <Spinner />
  } else {
    pageContent = ''
  }

  return (
    <React.Fragment>
      {errors && errors.message && (
        <Alert variant="danger" style={{ marginTop: '15px' }}>
          <strong>Error!</strong> {errors.message}
        </Alert>
      )}
      {formShow === 0 && (
        <Form1
          dropDownData={dropDownData}
          formShowHandler={(num) => formShowHandler(num)}
          form1DataHandler={form1DataHandler}
          modelHanlder={modelHanlder}
          form1Data={form1Data}
          singlePropertyData={singlePropertyData}
        />
      )}
      {formShow === 1 && (
        <Form2
          dropDownData={dropDownData}
          formShowHandler={(num) => formShowHandler(num)}
          form2DataHandler={form2DataHandler}
          form2Data={form2Data}
          singlePropertyData={singlePropertyData}
        />
      )}
      {formShow === 2 && (
        <Form3
          dropDownData={dropDownData}
          formShowHandler={(num) => formShowHandler(num)}
          form3DataHandler={form3DataHandler}
          form3Data={form3Data}
          modelHanlder={modelHanlder}
          loading={loading}
          singlePropertyData={singlePropertyData}
        />
      )}
      {pageContent}
    </React.Fragment>
  )
}
