import {
  PAGE_LOADING_MPAC,
  PAGE_LOADED_MPAC,
  SET_PRODUCT_CATALOGUE_MPAC,
  SET_REPORTS_MPAC,
  SET_MPAC_PROPERTY_RESULT,
  SET_MPAC_PROPERTY_AUTH_TOKEN,
  SET_MPAC_RECEIPT_DATA,
  SET_MPAC_AUTO_FILL_PROPERTY_DATA,
} from '../actions/actionTypes'

const initialState = {
  loading: false,
  productCatalogue: {},
  reports: [],
  mpacPropertyResult: {},
  authToken: '',
  receiptData: {},
  propertyData: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADING_MPAC:
      return {
        ...state,
        loading: true,
      }
    case PAGE_LOADED_MPAC:
      return {
        ...state,
        loading: false,
      }
    case SET_PRODUCT_CATALOGUE_MPAC:
      return {
        ...state,
        productCatalogue: action.payload,
      }
    case SET_REPORTS_MPAC:
      return {
        ...state,
        reports: action.payload,
      }
    case SET_MPAC_PROPERTY_RESULT:
      return {
        ...state,
        mpacPropertyResult: action.payload,
      }
    case SET_MPAC_PROPERTY_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      }
    case SET_MPAC_RECEIPT_DATA:
      return {
        ...state,
        receiptData: action.payload,
      }
    case SET_MPAC_AUTO_FILL_PROPERTY_DATA:
      return {
        ...state,
        propertyData: action.payload,
      }
    default:
      return state
  }
}
