import axios from 'axios'

import {
  SET_ERRORS,
  CLEAR_ERRORS,
  PAGE_LOADING_MPAC,
  PAGE_LOADED_MPAC,
  SET_PRODUCT_CATALOGUE_MPAC,
  SET_REPORTS_MPAC,
  SET_MPAC_PROPERTY_RESULT,
  SET_MPAC_PROPERTY_AUTH_TOKEN,
  SET_MPAC_RECEIPT_DATA,
  SET_MPAC_AUTO_FILL_PROPERTY_DATA,
} from './actionTypes'

let backendServerURL = process.env.REACT_APP_API_URL

export const setPageLoading = () => {
  return {
    type: PAGE_LOADING_MPAC,
  }
}

export const clearPageLoading = () => {
  return {
    type: PAGE_LOADED_MPAC,
  }
}
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const setErrors = (error) => {
  return {
    type: SET_ERRORS,
    payload: error,
  }
}

// Get product catalogue for screen 1
export const getProductCatalogue = () => (dispatch) => {
  dispatch(setPageLoading())
  axios
    .post(backendServerURL + '/getProductCatalogue', {
      app: 'houseup',
    })
    .then((res) => {
      const { resultCode, data } = res.data
      if (resultCode === '200') {
        dispatch({
          type: SET_PRODUCT_CATALOGUE_MPAC,
          payload: data ? data : {},
        })
        dispatch(clearErrors())
      } else {
        dispatch(
          setErrors({
            message: res.data.message
              ? res.data.message
              : 'Something went wrong! While fetching product catalogue.',
          }),
        )
      }
    })
    .catch((err) => {
      dispatch(
        setErrors(
          err && err.response && err.response.data ? err.response.data : {},
        ),
      )
    })
    .finally(() => dispatch(clearPageLoading()))
}

// Get reports from backend for screen 2
export const getMPACReport = () => (dispatch) => {
  dispatch(setPageLoading())
  axios
    .post(backendServerURL + '/getMPACReport', {
      app: 'houseup',
    })
    .then((res) => {
      const { resultCode, data } = res.data
      if (resultCode === '200') {
        dispatch({
          type: SET_REPORTS_MPAC,
          payload:
            data && data.reports && data.reports.length ? data.reports : [],
        })
        dispatch(clearErrors())
      } else {
        dispatch(
          setErrors({
            message: res.data.message
              ? res.data.message
              : 'Something went wrong! While fetching mpac reports.',
          }),
        )
      }
    })
    .catch((err) => {
      dispatch(
        setErrors(
          err && err.response && err.response.data ? err.response.data : {},
        ),
      )
    })
    .finally(() => dispatch(clearPageLoading()))
}

// getAuthTokenMPACAPI from backend for screen 3
export const getAuthTokenMPACAPI = async () => {
  const reqPacket = {
    userID: 'houseup',
    password: 'de1c96612424132b6d744b1489790f',
  }
  try {
    return await axios.post(
      backendServerURL + '/getAuthTokenMPACAPI',
      reqPacket,
    )
  } catch (error) {
    throw error
  }
}

// Get MPac property from backend for screen 3
export const getMPACProperty = (reqPacket) => async (dispatch) => {
  dispatch(setPageLoading())
  const { data: authData } = await getAuthTokenMPACAPI()
  if (authData.resultCode === '00') {
    axios
      .post(backendServerURL + '/getMPACProperty', reqPacket, {
        headers: {
          authtoken: authData.data.authToken,
        },
      })
      .then((res) => {
        const { resultCode, data } = res.data
        if (resultCode === '00') {
          dispatch({
            type: SET_MPAC_PROPERTY_AUTH_TOKEN,
            payload: authData.data.authToken,
          })
          dispatch({
            type: SET_MPAC_PROPERTY_RESULT,
            payload: data,
          })
          dispatch(clearErrors())
        } else {
          dispatch(
            setErrors({
              message: res.data.message
                ? res.data.message
                : 'Something went wrong! While confirming property.',
            }),
          )
        }
      })
      .catch((err) => {
        dispatch(
          setErrors(
            err && err.response && err.response.data ? err.response.data : {},
          ),
        )
      })
      .finally(() => dispatch(clearPageLoading()))
  } else {
    dispatch(
      setErrors({
        message: authData.message,
      }),
    )
  }
}

// getMPACPaymentReceipt from backend for screen 4
export const getMPACPaymentReceipt = (reqPacket, authToken) => (dispatch) => {
  dispatch(setPageLoading())
  axios
    .post(backendServerURL + '/getMPACPaymentReceipt', reqPacket, {
      headers: {
        authtoken: authToken,
      },
    })
    .then((res) => {
      const { resultCode, data } = res.data
      if (resultCode === '200') {
        dispatch({
          type: SET_MPAC_RECEIPT_DATA,
          payload: data,
        })
        dispatch(clearErrors())
      } else {
        dispatch(
          setErrors({
            message: res.data.message
              ? res.data.message
              : 'Something went wrong! While fetching receipts.',
          }),
        )
      }
    })
    .catch((err) => {
      dispatch(
        setErrors(
          err && err.response && err.response.data ? err.response.data : {},
        ),
      )
    })
    .finally(() => dispatch(clearPageLoading()))
}

// mpacPayment from backend for screen 4
export const mpacPayment =
  (reqPacket, authToken, productCatalogueName, autoFillReqPacket, history) =>
  (dispatch) => {
    dispatch(setPageLoading())
    axios
      .post(backendServerURL + '/mpacPayment', reqPacket, {
        headers: {
          authtoken: authToken,
        },
      })
      .then((res) => {
        const { resultCode } = res.data
        if (resultCode === '200') {
          dispatch(clearErrors())
          if (productCatalogueName === 'mpac') {
            dispatch(
              getMPACPropertyRESDETL2(autoFillReqPacket, authToken, history),
            )
          } else {
            history.push('/add-property')
          }
        } else {
          dispatch(
            setErrors({
              message: res.data.message
                ? res.data.message
                : 'Something went wrong! While making payment.',
            }),
          )
        }
      })
      .catch((err) => {
        dispatch(
          setErrors(
            err && err.response && err.response.data ? err.response.data : {},
          ),
        )
      })
      .finally(() => dispatch(clearPageLoading()))
  }

// getMPACPropertyRESDETL2 from backend for screen 4
export const getMPACPropertyRESDETL2 =
  (reqPacket, authToken, history) => (dispatch) => {
    dispatch(setPageLoading())
    axios
      .post(backendServerURL + '/getMPACPropertyRESDETL2', reqPacket, {
        headers: {
          authtoken: authToken,
        },
      })
      .then((res) => {
        const { resultCode, data } = res.data
        if (resultCode === '00') {
          dispatch({
            type: SET_MPAC_AUTO_FILL_PROPERTY_DATA,
            payload: data,
          })
          dispatch(clearErrors())
          history.push('/add-property')
        } else {
          dispatch(
            setErrors({
              message: res.data.message
                ? res.data.message
                : 'Something went wrong! While making payment.',
            }),
          )
        }
      })
      .catch((err) => {
        dispatch(
          setErrors(
            err && err.response && err.response.data ? err.response.data : {},
          ),
        )
      })
      .finally(() => dispatch(clearPageLoading()))
  }
