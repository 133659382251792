import React from 'react'
import ReactDOM from 'react-dom'

// Router and Redux setup setup start
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

//importing redux
import pageReducer from './store/reducers/pageReducer'
import vendorReducer from './store/reducers/vendorReducers'
import authReducer from './store/reducers/authReducer'
import errorsReducer from './store/reducers/errorReducer'
import propertyReducer from './store/reducers/propertyReducer'
import mpacReducer from './store/reducers/mpacReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  page: pageReducer,
  vendor: vendorReducer,
  auth: authReducer,
  errors: errorsReducer,
  property: propertyReducer,
  mpac: mpacReducer,
})

//const store = createStore(burgerBuilderReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const app = (
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
