import React from 'react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

const addressParser = (addressComponentArray) => {
  let streetNumber = ''
  let streetName = ''
  let postalCode = ''
  if (addressComponentArray && addressComponentArray.length) {
    addressComponentArray.map((data) => {
      const type =
        data.types && data.types.length && data.types.length
          ? data.types[0]
          : ''

      if (type === 'street_number') {
        streetNumber = data.short_name
      }
      if (type === 'route') {
        streetName = data.short_name
      }
      if (type === 'postal_code') {
        postalCode = data.short_name
      }
      return data
    })
  }
  return [streetNumber, streetName, postalCode]
}

const SelectLocation = ({
  activeTabHandler,
  onChangeHandler,
  autoFillAddressHandler,
  locationSubmitHandler,
  handleAddressChange,
  streetName,
  streetNumber,
  postalCode,
  address,
}) => {
  const handleAddressSelect = async (address, placeId) => {
    handleAddressChange(address)
    try {
      const result = await geocodeByPlaceId(placeId)
      const [sNumber, sName, pCode] = addressParser(
        result && result.length && result[0].address_components
          ? result[0].address_components
          : [],
      )
      autoFillAddressHandler(sNumber, sName, pCode)
    } catch (error) {
      console.log('checking geocodeByPlaceId error: ', geocodeByPlaceId)
    }
  }

  const searchOptions = {
    componentRestrictions: { country: ['ca'] },
    types: ['address'],
  }

  return (
    <div className="wrapper">
      <form
        className="mpac_wrapper mpac_search_form"
        onSubmit={locationSubmitHandler}
      >
        <div className="form-group">
          <img
            src={require('../../../../assets/mpac_images/search_icon.png')}
            alt="mpac_search_icon"
            className="mpac_search_icon"
          />
          <PlacesAutocomplete
            value={address}
            onChange={handleAddressChange}
            onSelect={handleAddressSelect}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="form-group">
                <input
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className:
                      'location-search-input input-feilds-property mpac_street_search',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item'
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          position: 'relative',
                          backgroundColor: '#fafafa',
                          cursor: 'pointer',
                        }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' }
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="form-group">
          <label>Street number</label>
          <input
            type="text"
            name="streetNumber"
            value={streetNumber}
            onChange={onChangeHandler}
            required
          />
        </div>
        <div className="form-group">
          <label>Street name</label>
          <input
            type="text"
            name="streetName"
            value={streetName}
            onChange={onChangeHandler}
            required
          />
        </div>
        <div className="form-group">
          <label>Postal Code</label>
          <input
            type="text"
            name="postalCode"
            value={postalCode}
            onChange={onChangeHandler}
            required
          />
        </div>
        <button
          onClick={() => activeTabHandler(1)}
          className="mpac_listing_anchor_skip"
        >
          Back
        </button>
        <div className="form-group">
          <input type="submit" name="mpac_submit_btn" defaultValue="Continue" />
        </div>
      </form>
      <div className="clear" />
    </div>
  )
}

export default SelectLocation
