import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { validateIsNumber } from '../../../utils'

export default ({
  form2Data,
  singlePropertyData,
  form2DataHandler,
  formShowHandler,
  dropDownData,
}) => {
  const { user } = useSelector((state) => state.auth)

  const [currencyId, setCurrencyId] = useState(2)
  const [description, setDescription] = useState('')
  const [adTitle, setAdTitle] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [price, setPrice] = useState(0)
  const [userId, setUserId] = useState(0)
  const [currencyData, setCurrencyData] = useState([])

  useEffect(() => {
    if (
      _.isEmpty(form2Data) &&
      singlePropertyData &&
      !_.isEmpty(singlePropertyData)
    ) {
      setCurrencyId(singlePropertyData.currencyId ?? 2)
      setDescription(singlePropertyData.description ?? '')
      setAdTitle(singlePropertyData.adTitle ?? '')
      setPrice(singlePropertyData.price ?? 0)
    } else {
      if (form2Data) {
        setCurrencyId(form2Data.currencyId ?? 2)
        setDescription(form2Data.description ?? '')
        setAdTitle(form2Data.adTitle ?? '')
        setPrice(form2Data.price ?? 0)
      }
    }
  }, [form2Data, singlePropertyData])

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setContactEmail(user.emailAddress ?? '')
      setContactName(`${user.firstName ?? ''} ${user.lastName ?? ''}`)
      setContactNumber(user.msisdn ?? '')
      setUserId(user.userId ?? 0)
    }
  }, [user])

  useEffect(() => {
    if (dropDownData?.currencies?.length && currencyData.length === 0) {
      setCurrencyData(dropDownData.currencies)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownData])

  const priceChangeHandler = (e) => {
    const { value, name } = e.target
    if (name === 'price') {
      if (validateIsNumber(value)) {
        setPrice(value)
      }
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const form2DataPacket = {
      description,
      currencyId:
        currencyId === ''
          ? currencyData?.length && currencyData[0].id
          : currencyId,
      adTitle,
      contactName,
      contactEmail,
      contactNumber,
      price: Number(price),
      userId,
    }
    form2DataHandler(form2DataPacket)
    formShowHandler(2)
  }

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div
          className="add-property-conatiner"
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div className="row border-property">
            <div className="col-md-12">
              <h1 className="titles-property">List your property</h1>
              <p className="pairing-industry">
                Pairing the industry's top technology with unsurpassed local
                expertise.
              </p>
              <Nav variant="pills" defaultActiveKey="/2">
                <Nav.Item>
                  <Nav.Link
                    eventKey="/1"
                    className="tabs"
                    onClick={() => formShowHandler(0)}
                  >
                    Step 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/2"
                    className="tabs"
                    onClick={() => formShowHandler(1)}
                  >
                    Step 2
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="/3"
                    className="tabs"
                    onClick={() => formShowHandler(2)}
                  >
                    Step 3
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h6 className="titles-property">*Ad title</h6>
              <input
                className="input-feilds-property"
                placeholder="Enter a title for your property..."
                name="adTitle"
                value={adTitle}
                onChange={(e) => setAdTitle(e.target.value)}
                required
              />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <h6 className="titles-property">Currency</h6>
                  <select
                    className="input-feilds-property"
                    onChange={(e) => setCurrencyId(e.target.value)}
                    name="currencyId"
                    value={currencyId}
                    required
                  >
                    {currencyData && currencyData.length
                      ? currencyData.map((currency, idx) => (
                          <option key={idx} value={currency.id}>
                            {' '}
                            {currency.lable}
                          </option>
                        ))
                      : ''}
                  </select>
                </div>
                <div className="col-md-8">
                  <h6 className="titles-property">*Price</h6>
                  <input
                    type="text"
                    className="input-feilds-property"
                    onChange={priceChangeHandler}
                    placeholder="$"
                    name="price"
                    value={price}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row border-property pt-10">
            <div className="col-md-12">
              <h6 className="titles-property">Description</h6>
              <textarea
                className="input-feilds-property"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="More detail about your property..."
                style={{ height: '120px' }}
              />
            </div>
          </div>

          <h1 className="titles-property" style={{ fontFamily: 'light' }}>
            Contact info
          </h1>
          <p className="titles-property">
            You can edit your contact info anytime in your profile settings.
          </p>
          <div className="row">
            <div className="col-md-4 mb-10">
              <h6 className="titles-property">Contact email</h6>
              <input
                type="text"
                className="input-feilds-property"
                onChange={(e) => setContactEmail(e.target.value)}
                placeholder="email"
                name="contactEmail"
                value={contactEmail}
                required
              />
              <span className="title-property">
                Your email will not be shared.
              </span>
            </div>
            <div className="col-md-4 mb-10">
              <h6 className="titles-property">Contact name</h6>
              <input
                type="text"
                className="input-feilds-property"
                onChange={(e) => setContactName(e.target.value)}
                value={contactName}
                placeholder="name"
                name="contactName"
                required
              />
            </div>
            <div className="col-md-4 mb-10">
              <h6 className="titles-property">Contact number</h6>
              <input
                type="text"
                className="input-feilds-property"
                placeholder="Contact number"
                onChange={(e) => setContactNumber(e.target.value)}
                name="contactNumber"
                value={contactNumber}
                required
              />
            </div>
          </div>
          <div className="row mb-40">
            <div className="col-6">
              <div className="btn-div-prev">
                <button
                  className="btn btn-lg btn-primary btn-property"
                  onClick={() => formShowHandler(0)}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className="btn-div">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary btn-property"
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}
